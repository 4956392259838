@import 'styles/common.scss';

.folderContainer {
  padding: 0;
  height: 100%;
  border-radius: 0px;
  border-top: none;
  display: flex;
  flex-direction: column;

  @include mobile {
    min-width: 100vw;
    width: 100%;
  }

  .composeButtonSection {
    padding: 24px 16px;
  }

  .sectionItemList {
    height: 100%;
    overflow: hidden;
  }

  .inboxMeter {
    padding: 8px;
    margin-top: 2px;
    border-top: 1px solid $kite-gray-2;
  }
}
