@import '../../styles/common.scss';

.navContainer {
  @include wh(60px, 100%);
  position: relative;
  box-shadow: 1px 0px 0px $kite-gray;
  background-color: $kite-white;

  @media only screen and (max-width: 1200px) {
    margin-bottom: 30px;
  }

  @include mobile {
    display: none;
  }
}
