@import 'styles/common.scss';

.searchBarContainer {
  @include marLR(auto);
  display: flex;
  align-items: center;
  position: absolute;
  min-width: 320px;
  left: 300px;

  @include mobile {
    margin: 10px 30px;
    width: calc(100% - 60px);
    min-width: 280px;
    position: relative;
    background-color: $kite-white;
    left: unset;
  }

  @media (min-width: 1438px) {
    position: relative;
    left: unset;
    margin-left: 0px;
  }

  @media (min-width: 1196px) {
    width: 448px;
  }

  @media (max-width: 1066px) {
    min-width: 200px;
  }

  @media (max-width: 320px) {
    min-width: 0;
  }

  .searchBarInput {
    @include wh(100%, 38px);
    min-width: 320px;
    border: 1px solid $kite-gray-2;
    padding-left: 46px;
    padding-right: 36px;

    @media (max-width: 1100px) {
      min-width: 280px;
    }

    @include placeholder {
      @include placeholder-style;
    }

    @media (max-width: 320px) {
      min-width: 260px;
      max-width: 260px;
    }
  }

  :global(.kite-text-input input ~ .kite-form-control__bounding-box) {
    height: 36px;
    border: none;
    width: 400px;

    @media (max-width: 1195px) {
      width: unset;
    }

    @media (min-width: 1438px) {
      width: 358px;
    }
  }

  :global(.kite-text-input.kite-form-control__input) {
    max-width: unset;
  }

  :global(.kite-form-control .kite-text-input input) {
    padding-top: 6px;
    padding-bottom: 10px;
    height: 36px;
  }

  .searchButton {
    position: absolute;
    left: 16px;
    width: 30px;
    height: 30px;
    padding: 0;
    z-index: 1;

    .searchIcon {
      @include mobile {
        left: 18px;
      }
    }
  }

  .cancelSearch {
    position: absolute;
    right: 20px;
    padding: 0px;
    width: 20px;
    height: 20px;
  }

  .searchOptionButton {
    @include wh(116px, 44px);
    display: none;
    border: 1px solid $kite-gray-2;
    margin-left: -2px;
    background-color: $kite-gray-1;

    .searchOptionsButtonContent {
      @include flexCenter();
      padding-left: 10px;
      position: relative;

      .optionsChevron {
        padding-left: 5px;
        width: 30px;
      }
    }
  }
}
