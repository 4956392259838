@import 'styles/common.scss';
$filterItemHight: 120px;

.kiteSwitchOverride {
  --kite-switch-label-margin-right: 10px;
}

.filterContainer {
  position: relative;

  .title {
    @include flex(row, space-between);
    @extend .kiteSwitchOverride;
    margin-bottom: 10px;
    & > * {
      margin: 0;
    }
  }

  .overlayLoader {
    z-index: 1;
  }

  .subTitle {
    font-size: 14px;
  }

  .noRulesTitle {
    font-size: 16px;
  }

  .filterListContainer {
    height: 100%;
    position: relative;
  }

  .rulesList {
    list-style: none;
    padding: 0;
    margin: 0;

    .rulesListItem {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 16px 0;
      border-bottom: 1px solid $kite-gray-2;
      &:hover {
        background-color: $kite-light-blue-2;
        cursor: pointer;
      }

      &:last-child {
        border-bottom: none;
      }

      .rulesSort {
        position: relative;
        flex: 0 1 24px;
      }

      .ruleChevron::before {
        content: none;
      }

      .ruleChevron:focus-visible {
        outline: auto;
        outline-offset: -7px;
      }

      .rulesContent {
        display: flex;
        flex-direction: column;
        flex: 1 0.75 90%;
        .rulesTitle {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          display: flex;

          .rulesTitleName {
            font-size: 16px;
            font-weight: 400;
          }

          .actionButtons {
            padding-right: 16px;
            font-size: 16px;
            font-weight: 400;

            .deleteButton {
              color: $kite-red-20;
            }
          }
        }

        .description {
          font-size: 14px;
          color: $kite-gray-30;
          padding-right: 16px;
          word-break: break-all;
        }
      }
    }
  }

  .createSection {
    margin-top: 24px;
  }

  .spinnerContainer {
    min-height: 60px;
  }
}
