@import 'styles/common.scss';

.headerDesktop {
  font-size: 24px;
  font-weight: 500px;
  margin-bottom: 14px;

  @include mobile {
    margin-top: 26px;
    margin-bottom: 0px;
    display: none;
  }
}

.headerMobile {
  font-size: 24px;
  font-weight: 500px;
  display: none;

  @include mobile {
    display: block;
  }
}

.columnHeaderSticky {
  display: none;
  flex-direction: row;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-left: 32px;
  box-sizing: content-box;
  margin-right: 50px;
  width: 100%;

  div {
    border-bottom: 1px solid $kite-gray-2;
    padding: 12px 8px;
  }

  .displayNameColumn {
    width: 185px;
  }

  .emailColumn {
    width: 262px;
  }

  .firstNameColumn,
  .lastNameColumn {
    width: 146px;
  }
}

.showSticky {
  display: flex;

  @include mobile {
    display: none;
  }
}

.scrollbar {
  min-height: 268px;

  > :first-child {
    padding: 4px;
  }

  .errorAlert {
    margin-bottom: 16px;

    @include mobile {
      margin-top: 10px;
    }
  }

  .inputWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include mobile {
      flex-direction: column-reverse;
      gap: 8px;
    }

    .selectLabel {
      width: 184px;

      @include mobile {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    .searchWrapper {
      position: relative;
      .icon {
        top: 36px;
        left: 10px;
        position: absolute;
        z-index: 100;

        @include mobile {
          top: 22px;
        }
      }

      .searchInput {
        margin-top: 24px;
        width: 304px;
        height: fit-content;

        @include mobile {
          width: 100%;
          margin-top: 10px;
        }

        :global(.kite-text-input) {
          input {
            padding-left: 34px;
          }
        }
      }
    }
  }

  .resultSortWrapper {
    display: flex;
    justify-content: space-between;
    position: relative;

    .resultCount {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: $kite-gray-3;
      margin-top: 10px;
      margin-bottom: 16px;
    }

    .sortWrapper {
      display: flex;

      .sortLabel {
        font-weight: 700;
        margin-top: 10px;
        padding-right: 4px;
      }

      :global(.kite-menu) {
        top: 0px;
        right: 0px;
        position: relative;
        display: inline-block;
      }
    }
  }

  .selectAllWrapper {
    width: fit-content;
  }

  .tableContainer {
    display: flex;
    width: 100%;
    overflow-y: scroll;
    overflow-y: hidden;

    .checkboxContainer {
      display: flex;
      flex-direction: column;
      gap: 2px;
      margin-top: 62px;
      min-width: 28px;

      @include mobile {
        margin-top: 56px;

        .checkbox {
          margin-bottom: 21px;
        }
      }
    }

    .removeMargin {
      margin-top: 14px;

      @include mobile {
        margin-top: 56px;
      }
    }

    .kiteTableWrapper {
      flex-grow: 1;
    }

    .hideColumns {
      thead {
        display: none;

        @include mobile {
          display: table-header-group;
        }
      }
    }
  }
}

.cta {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 400px;

  @include mobile {
    width: 100%;
    margin-top: 22px;
    flex-direction: column;
    gap: 12px;
  }
}

tr {
  th {
    min-width: 146px;
  }
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 146px;
  }
  .displayNameColumn {
    min-width: 185px;
    max-width: 185px;
    width: 185px;
  }
  .emailColumn {
    min-width: 262px;
    max-width: 262px;
    width: 262px;
  }
  .firstNameColumn,
  .lastNameColumn {
    min-width: 146px;
    max-width: 146px;
    width: 146px;
  }
}

.errorHeaderContainer {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;

  .errorIcon {
    margin-top: 4px;

    @include mobile {
      margin-top: 1px;
    }
  }

  .header {
    font-size: 24px;
    margin-bottom: 0px;

    @include mobile {
      font-size: 20px;
    }
  }
}

.ok {
  margin-top: 30px;
  width: 180px;

  @include mobile {
    width: 100%;
  }
}
