@import 'styles/common.scss';

.kiteSwitchOverride {
  --kite-switch-label-margin-right: 10px;
}

.spanManagementContainer {
  .kiteCardSpam {
    padding: 16px;
    height: fit-content;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    border-radius: 0;

    .title {
      @include flex(row, space-between);
      @extend .kiteSwitchOverride;
      margin-bottom: 10px;
      & > * {
        margin: 0;
      }
    }

    .spamDescription {
      font-size: 14px;
    }

    .spamForm {
      margin-top: 15px;
    }
  }
}
