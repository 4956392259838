@import 'styles/common.scss';

.headerContainer {
  height: 56px;
  width: 100%;
  background-color: $kite-dark-blue-1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid $kite-gray-2;
  margin-top: -0.5px;

  .title {
    line-height: 56px;
    max-width: 510px;
    padding-left: 16px;
    overflow: hidden;
    white-space: nowrap;
    color: $kite-white;
    text-overflow: ellipsis;
  }

  .iconGroupContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 10px;
    gap: 8px;

    .composeButtons {
      background-color: transparent;
      color: $kite-white;
      border: none;
      cursor: pointer;
      padding: 4px;

      &.composeButtonsHideMobile {
        @include mobile {
          display: none;
        }
      }
    }

    .closeButton {
      color: $kite-white;
    }

    .rk-button {
      padding: 0px;
    }
  }
}

.minimizedWidth {
  width: 304px;
  border-radius: 4px;
  margin-left: 8px;
}
