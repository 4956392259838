@import 'styles/common.scss';

.headerContainer {
  display: flex;
  gap: 8px;
}

.ok {
  width: 244px;
}

.errorMessageScroll {
  min-height: 20vh;
  max-height: 30vh;
  width: 100%;
  margin-bottom: 20px;
}
