@import 'styles/common.scss';

.textEditorContainer {
  .textEditorLoader {
    position: absolute;
    left: 50%;
    bottom: 50%;
    z-index: 5;
  }
}

.accessibleToolbar {
  color: $kite-gray-30;
  position: absolute;
  left: -20000px;

  &:focus {
    position: initial;
    margin-left: 5px;
  }
}
// compose-text-editor
.textEditorCompose {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  min-height: 240px;

  div {
    font-size: 16px;
    line-height: 20px;
  }

  @include mobile {
    min-height: calc(100vh - 413px);
  }

  :global(.mce-content-body) {
    min-height: inherit;
    height: 100%;
    outline: none;
  }
  :global(.tox.tox-tinymce) {
    border: none;
    @include mobile {
      width: 100%;
    }

    :global(.tox-toolbar__primary) {
      border: 1px solid #d8dde6;
      border-radius: 4px;

      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

// settings-text-editor
.textEditorSettings {
  position: relative;
  :global(.tox.tox-tinymce) {
    border: 1px solid #d8dde6;
    @include mobile {
      width: 100%;
    }
    :global(.tox-sidebar-wrap) {
      margin: 1rem;
    }
    :global(.tox-toolbar__primary) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .accessibleToolbar:focus {
    margin-bottom: 4px;
  }
}
