@import 'styles/common.scss';

.settingMenuCell {
  width: 260px;

  @include mobile {
    width: 100%;
  }
  .settingContainer {
    padding: 0;
    border-radius: 0;
    height: 100%;
    border-top: none;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .sectionItemList {
      .simpleBar {
        min-height: calc(100vh - 260px);
        @include mobile {
          max-height: fit-content;
          height: fit-content;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            list-style: none;
            padding: 0;
            margin: 0;
          }
        }
      }
    }

    .supportLink {
      @include flex(row, null, center);
      color: $kite-blue-2;

      .supportIcon {
        margin-right: 10px;
      }
    }
    .inboxMeter {
      padding: 14px;
      border-top: 1px solid $kite-gray-2;
      width: 100%;

      @include mobile {
        position: initial;
      }
    }
  }
}
