@import 'styles/common.scss';

.errorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: $kite-white;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 466px;
    text-align: center;

    .icon {
      margin-bottom: 24px;
    }

    .header {
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px;
      margin-bottom: 16px;
    }

    .description {
      padding: 0 16px;
      margin-bottom: 48px;
    }

    .okButton {
      max-width: 210px;
    }
  }
}
