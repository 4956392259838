@import 'styles/common.scss';

.section {
  @include flex(row, flex-start, center);
  @include mobile {
    @include flex(row, space-between, center);
  }
  width: 100%;

  .sectionLeft {
    :global(.kite-button.focus-visible) {
      height: 24px;
    }

    @media only screen and (max-width: 366px) {
      button {
        padding-left: 8px;
        padding-right: 8px;
      }
      :first-child {
        padding: 0px;
      }
      :last-child {
        padding: 0px;
      }
    }
    @include flex(row, flex-start, center);

    @include mobile {
      position: relative;
      left: -8px;
    }

    .mobileSection {
      display: none;

      @include mobile {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .sectionRight {
    @include flex(row, flex-end, center);
  }

  .selectButtonBorder {
    content: ' ';
    height: 26px;
    width: 1px;
    background-color: $kite-gray-2;
  }

  .messageOptionsButton {
    @include flexCenter();
    --icon-margin: 9px;
    font-weight: 400;

    @media only screen and (max-width: 320px) {
      --icon-margin: 4px;
    }
  }

  label.messageOptionsButton {
    color: $kite-blue-2;
    display: flex;
    gap: 4px;
    align-items: baseline;
    white-space: nowrap;
    margin-left: 10px;
    cursor: pointer;

    @include mobile {
      margin-right: 10px;
    }

    @media (max-width: 474px) {
      .label {
        display: none;
      }
    }
  }

  .deleteButton {
    color: $kite-red-20;
  }

  .otherActions {
    color: black;
    margin-right: 6px;
    @include mobile {
      display: none;
    }
  }
}

.plainText {
  padding-top: 30px;
  white-space: pre-line;
}

.iframe {
  @media print {
    border: none;
    font-size: 1em;
    p,
    div {
      width: 100%;
    }
  }
}

.hide {
  display: none;
}
