@import 'styles/common.scss';

.messageList {
  width: 100%;
  height: 100%;
  display: flex;

  .simpleBar {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px;

    @include mobile {
      min-height: 50vh;
      width: 100%;
    }

    .spinnerContainer {
      height: 100%;
      flex-grow: 1;
      border-left: 1px solid $kite-gray-2;
      border-right: 1px solid $kite-gray-2;

      @include mobile {
        padding: 0px;
      }

      .loader {
        height: 100% !important;
        position: relative;
        background-color: $kite-white;
      }

      @include mobile {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
      }
    }

    .listContainer {
      flex-grow: 1;
      border: 1px solid $kite-gray-2;
      border-top: 0;
      background-color: $kite-white;

      ul {
        padding: 0px;
        margin: 0px;
        border-bottom: 1px solid $kite-gray-2;
        margin-bottom: -1px;

        @include mobile {
          padding: 0px;
        }

        > li:last-child {
          border-bottom: none;
        }

        .emailCardThreePane {
          position: relative;
          border-bottom: 1px solid $kite-gray-2;
          background-color: $kite-white;

          @include mobile {
            width: 100%;
          }

          .selectedBorder {
            display: none;
          }

          &.itemSelected {
            background-color: $kite-light-blue-2;

            .selectedBorder {
              display: block;
              position: absolute;
              border-left: 4px solid $kite-blue-2;
              height: calc(100% - 1px);
              left: 0px;
              top: 0px;
            }
          }

          &.checked {
            background-color: $kite-light-blue-2;
          }

          &:hover {
            background: $kite-gray-1;
            cursor: pointer;
          }

          .messageDetails {
            list-style: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 0px;
            text-decoration: none;
            padding: 8px 16px;

            &:focus-visible {
              outline-offset: -3px;
            }

            .row {
              display: flex;
              width: 100%;
              margin-bottom: 8px;

              .unreadIconColumn {
                display: flex;
                align-items: center;
                width: 33px;

                .unreadIcon {
                  background-color: $kite-blue-2;
                  color: $kite-blue-2;
                  border-radius: 50%;
                  position: relative;
                  left: 2px;
                }
              }

              .columnTwo {
                padding-left: 8px;
                width: 100%;
                overflow: hidden;

                .fromNameWithIcons {
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 26px;
                  display: flex;
                  flex-flow: row nowrap;
                  margin-left: 30px;

                  .name {
                    flex: 1 1 auto;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-decoration: none;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 26px;
                    color: $kite-black;
                    margin-right: 8px;
                  }

                  .nameRead {
                    font-weight: 500;
                  }

                  .icons {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 4px;

                    :global(.kite-icon) {
                      height: 14px;
                      width: 14px;
                      align-self: center;
                      font-weight: 500;
                    }
                  }
                }

                .subjectWithDate {
                  display: flex;
                  justify-content: space-between;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  color: $kite-black;
                  white-space: nowrap;

                  .subject {
                    display: block;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    margin-right: 8px;
                  }

                  .unreadSubject {
                    font-weight: 700;
                  }
                }
              }

              .messagePreview {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: $kite-gray-3;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
            }

            .titleRow {
              margin-bottom: 2px;

              .hide {
                display: none;
              }
            }

            .subjectRow {
              margin-bottom: 0px;
            }
          }

          .messageCheckbox {
            position: absolute;
            top: 10px;
            left: 16px;
            user-select: none;
          }
        }

        .emailCardTwoPane {
          .messageDetails {
            flex-direction: row;
            width: 100%;
            padding: 4px 16px;

            .titleRow {
              width: 30%;

              .fromNameWithIcons {
                display: flex;
                width: 100%;
                margin-left: 0px !important;

                .name {
                  margin-left: 34px;
                }
              }
            }

            .subjectRow {
              width: 70%;
              display: flex;

              .unreadIconColumn {
                display: none;
              }

              .columnTwo {
                display: flex;
                justify-content: space-between;
                width: 100%;

                .subjectWithDate {
                  width: 100%;
                }
              }
            }
          }

          .messageCheckbox {
            position: absolute;
            top: 6px;
            left: 50px;
            user-select: none;
          }
        }

        .emptyFolderListPlaceHolder {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          background-color: $kite-white;
          border-top: 0;

          .emptyFolderListPlaceHolderIcon {
            margin-bottom: 16px;
          }
        }

        .errorContainer {
          background-color: $kite-white;
          height: 100%;

          @include mobile {
            min-height: 60vh;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .fullWidth {
        height: 100%;
      }
    }

    .paginationContainer {
      display: flex;
      position: relative;
      align-items: center;
      width: 100%;
      justify-content: center;
      margin-top: 14px;
      padding-bottom: 24px;

      @include mobile {
        margin-bottom: 25px;
        margin-top: 25px;
        min-height: initial;
      }
    }
  }

  .threePaneHeight {
    height: 100% !important;
  }
}
