@import 'styles/common.scss';

.landingPageContainer {
  @media (max-width: 1280px) {
    width: fit-content;
  }

  @include mobile {
    overflow-x: hidden;
  }
}
.canaryMainContent {
  width: 1280px;
  margin: 0 auto;
  gap: 30px;
  padding-top: 50px;

  @include mobile {
    padding-top: 25px;
    width: 100%;
    gap: 0px;
  }

  .introductionHeaderSection {
    margin: 30px 30px 30px 95px;
    width: 840px;

    @include mobile {
      margin: 0px;
      margin-bottom: 20px;
      width: 100%;
    }

    .header {
      padding-left: 30px;
      font-weight: 800;
      font-size: 64px;
      line-height: 72px;
      max-width: 700px;

      @include mobile {
        padding-left: unset;
        margin: 10px 20px;
        font-weight: 700;
        font-size: 34px;
        line-height: 40px;
      }
    }
  }

  .seeMoreContainer {
    display: flex;
    justify-content: center;

    .button {
      width: fit-content;

      span {
        color: $kite-black;
        margin-right: 8px;

        svg {
          color: $kite-blue-2;
        }
      }
    }
  }

  .emailIntroductionSection {
    display: flex;
    align-self: center;

    @include mobile {
      .image {
        display: flex;
        justify-content: center;
      }
    }

    @include mobile {
      flex-direction: column;
      margin: 0px 20px;
    }

    .leftPanelImage {
      @include wh(727px, auto);

      @include mobile {
        @include wh(100%, auto);
        max-width: 450px;
      }
    }

    .rightPanel {
      width: 371px;
      gap: 15px;
      margin-top: 138px;

      @include mobile {
        margin-top: 40px;
        margin-bottom: 20px;
      }

      .rightPanelText {
        color: $canaryBlueGrey;
        width: 371px;

        @include mobile {
          width: unset;
          max-width: 371px;
        }
      }

      .rightPanelButton {
        @include wh(260px, 44px);
        width: 260px;
        height: 44px;
        margin-top: 20px;

        @include mobile {
          margin-top: 10px;
          width: 100%;
        }
      }

      .actionButton {
        display: flex;
        gap: 16px;
      }

      @include mobile {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0px;

        .rightPanelText {
          text-align: center;
        }

        .actionButton {
          display: block;
          width: 100%;

          .rightPanelButton {
            margin-bottom: 24px;
          }
        }
      }
    }
  }

  .updatedPerformanceSection {
    margin-top: 98px;
    display: flex;
    margin-left: 63px;

    @include mobile {
      margin: 30px 20px;
      flex-direction: column-reverse;
      gap: 30px;

      .image {
        display: flex;
        justify-content: center;
      }
    }

    .leftPanelHeader {
      @include wh(371px, 126px);
      margin-top: 15px;
      font-size: 36px;
      margin-left: 55px;

      @include mobile {
        @include wh(100%, 100%);
        font-size: 28px;
        margin: 0px;
      }
    }

    .leftSecondaryText {
      @include wh(371px, 160px);
      color: $canaryBlueGrey;
      margin-top: 28px;
      margin-left: 55px;

      @include mobile {
        width: 100%;
        margin: 20px 0px;
        margin-bottom: 0px;

        p {
          height: fit-content;
        }
      }
    }

    .rightPanelImage {
      @include wh(675px, auto);
      width: 675px;
      height: auto;

      @include mobile {
        @include wh(100%, auto);
        max-width: 450px;
        margin: 0px;
      }
    }
  }

  .featureListSection {
    @include marTB(108px, 120px);
    display: flex;
    align-self: center;

    @include mobile {
      @include marTB(0px, 0px);
      flex-direction: column;
      margin: 40px 20px 20px;
      width: 100%;
    }

    .featureListBox {
      @include wh(344px, 254px);
      @include flexCenter(column);

      @include mobile {
        width: 100%;
        flex-direction: column;
        margin: auto;
      }
    }

    .filled {
      background-color: $canaryDarkBlue;
      border-radius: 8px 0px 0px 8px;
      text-align: center;
      box-shadow: 0px 2px 8px $canaryBoxShadow;

      @include mobile {
        border-radius: 8px;
      }

      .icon {
        color: $white;
        margin-top: 10px;
        margin-bottom: 9px;
      }

      .primaryText {
        @include wh(242px, 45px);
        font-size: 28px;
        color: $white;
        margin-bottom: -5px;
      }

      .secondaryText {
        @include wh(244px, 86px);
        font-size: 16px;
        color: $white;
      }
    }

    .unfilled {
      background-color: $white;
      border-radius: 0px 8px 8px 0px;
      text-align: center;

      @include mobile {
        border-radius: 8px;
      }

      .icon {
        @include marTB(10px, 9px);
      }

      .primaryText {
        @include wh(242px, 45px);
        font-size: 28px;
        margin-bottom: -5px;
      }

      .secondaryText {
        @include wh(244px, 86px);
        font-size: 16px;
      }
    }
  }

  .madeForMobileSection {
    display: flex;
    align-self: center;

    @include mobile {
      flex-direction: column;
      margin: 20px 20px;

      .image {
        display: flex;
        justify-content: center;

        img {
          width: 100px;
        }
      }
    }

    .image {
      @include wh(590px, auto);
      margin-left: 50px;
      margin-bottom: 132px;
      display: flex;
      gap: 10px;

      .leftPanelImage2 {
        padding-top: 100px;

        @include mobile {
          padding-top: 50px;
        }
      }

      @include mobile {
        @include wh(100%, auto);
        max-width: 450px;
        margin: 0px;
        margin-bottom: 20px;
      }
    }

    .rightPanelContainer {
      @include flex(column);
      margin-left: 115px;
      margin-top: 120px;

      @include mobile {
        margin: 0px;
      }

      .rightPanelPrimary {
        @include wh(355px, 42px);
        font-size: 36px;
        margin-bottom: 30px;

        @include mobile {
          @include wh(100%, 100%);
          margin-top: 20px;
          font-size: 28px;
          text-align: left;
          margin-bottom: 10px;
        }
      }

      .rightPanelSecondary {
        @include wh(366px, 119px);
        font-size: 16px;
        color: $canaryBlueGrey;

        @include mobile {
          @include wh(100%, 100%);
        }
      }
    }
  }

  .upgradeNowSection {
    @include flexCenter(column);
    text-align: center;
    margin-bottom: 228px;
    margin-left: -22px;

    @include mobile {
      margin: 0px 20px;
      margin-bottom: 120px;
    }

    .middlePanelPrimary {
      @include wh(766px, 42px);
      font-size: 36px;
      margin-bottom: 22px;

      @include mobile {
        font-size: 30px;
        margin-bottom: 18px;
        @include wh(100%, 42px);
      }
    }

    .middlePanelSecondary {
      @include wh(748px, 63px);
      color: $canaryBlueGrey;
      margin-bottom: 42px;

      @include mobile {
        @include wh(100%, 100%);
      }
    }

    .middlePanelAction {
      @include flex(row, center);
      width: 748px;

      @include mobile {
        flex-direction: column-reverse;
        align-items: center;
        gap: 20px;
        width: 100%;
      }

      .cancelButton {
        @include wh(260px, 44px);
        background-color: $white;
        border: 1px solid $canaryBlue;
        box-sizing: border-box;
        border-radius: 4px;
        color: $canaryBlue;
        margin-right: 15px;

        @include mobile {
          margin: 0;
        }
      }

      .confirmButton {
        background-color: $canaryBlue;
        border-radius: 4px;
        width: 260px;
        height: 44px;
        color: $white;
        margin-left: 15px;

        @include mobile {
          margin: 0;
        }
      }
    }
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.canarySpinner {
  width: '100vw';
  height: '100vh';
  background: '$white';
  z-index: 2000;
}

.modalOpen {
  max-height: 100vh;
  overflow-y: hidden;
}
