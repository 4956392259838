@mixin flex($dir: row, $just: initial, $items: initial) {
  display: flex;
  flex-direction: $dir;
  justify-content: $just;
  align-items: $items;
}

@mixin flexCenter($dir: row) {
  display: flex;
  flex-direction: $dir;
  justify-content: center;
  align-items: center;
}

@mixin wh($w, $h: $w) {
  width: $w;
  height: $h;
}

@mixin padLR($l, $r: $l) {
  padding-left: $l;
  padding-right: $r;
}

@mixin padTB($t, $b: $t) {
  padding-top: $t;
  padding-bottom: $b;
}

@mixin marLR($l, $r: $l) {
  margin-left: $l;
  margin-right: $r;
}

@mixin marTB($t, $b: $t) {
  margin-top: $t;
  margin-bottom: $b;
}

@mixin bor($col, $px: 1px, $type: solid) {
  border-width: $px;
  border-style: $type;
  border-color: $col;
}

@mixin mobile {
  @media only screen and (max-width: 1023px) {
    @content;
  }
}

@mixin borderRadius($value) {
  border-radius: $value;
}

@mixin placeholder-style {
  color: $kite-black;
  opacity: 1;
}

@mixin placeholder {
  &:-ms-input-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin verticalSeparator {
  margin-top: 4px;
  height: 16px;
  border-left: 1px solid $kite-black;
  vertical-align: middle;
}
