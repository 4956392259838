@import '../../../styles/common.scss';

.contactSuggestion {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 12px;
  width: 100%;

  mark {
    text-decoration: none !important;
  }
}

.invalidTag {
  background-color: $kite-red-20;
  color: white;

  &::after {
    color: white;
    border-color: white;
  }
}
