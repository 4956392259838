@import 'styles/common.scss';

.navSupportLinks {
  display: flex;
  align-items: center;

  .navSupportLink {
    display: flex;
    align-self: center;
    text-align: center;
    margin: 0px 20px;

    @include flexCenter(column);
    width: 100%;
    color: $kite-black;

    &:hover {
      cursor: pointer;
    }

    .navSupportLinkName {
      margin-top: 3px;
      margin-bottom: 0px;
    }
  }

  .verticalSeparator {
    @include verticalSeparator;
  }
}
