@import 'styles/common.scss';

.forwardingTray {
  .settingDeprecationAlert {
    margin-bottom: 12px;
  }

  .mailForwardingListCard {
    margin-top: 16px;
    padding: 24px 16px;

    .emailList {
      list-style: none;
      padding: 0;
      margin: 0;
      .emailItem {
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
        list-style: none;
        margin: 0;
        padding: 16px 0;
        border-bottom: 1px solid $kite-gray-2;
      }
    }
  }
}
