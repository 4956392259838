@import 'styles/common.scss';

.contactLayoutContainer {
  display: flex;
  height: 100%;
  .navigationContainerLayout {
    display: flex;
    flex-flow: column;
  }

  .contactContentLayout {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    overflow: hidden;

    @include mobile {
      overflow: scroll;
    }

    .options {
      display: flex;
      height: 42px;
    }
  }

  .contactGrid {
    height: 100%;
  }

  .noPointerEvents {
    pointer-events: none;
  }

  .gridCell {
    height: 100%;
  }

  .labelListGridCell {
    @extend .gridCell;
    @media only screen and (max-width: 1410px) {
      min-width: 232px;
    }
  }
  .contactListGridCell {
    @extend .gridCell;
    @media only screen and (max-width: 1410px) {
      min-width: 338px;
    }
  }

  .contactViewGridCell {
    @extend .gridCell;
    @media only screen and (max-width: 1410px) {
      width: calc(100vw - 630px);
    }
    .contactViewContainer {
      height: 100%;
    }
  }

  .wideListView,
  .wideReadingPane {
    width: 83.33% !important;

    @media only screen and (max-width: 1410px) {
      flex: 1;
    }

    @include mobile {
      width: initial;
    }
  }
}

.fullHeight {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.disableListView,
.disableReadingPane {
  display: none;

  @include mobile {
    display: initial;
  }
}
