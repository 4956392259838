@import 'styles/common.scss';

.mceToolbarContainer {
  height: 40px;
  &.mceToolbarHide {
    visibility: hidden;
    height: 0px;
  }
}

.toolbarContainer {
  display: flex;
  padding: 0;
  margin-top: 16px;
  padding-bottom: 16px;

  @include mobile {
    width: 100%;
    justify-content: space-between;
    margin-top: 8px;
    padding: 0px;
  }

  .formatIconDropDownContainer {
    background-color: $kite-white;
    display: block;
    flex-direction: column;
    position: absolute;
    bottom: 60px;
    right: 12px;
    border: 1px solid $kite-gray-2;
    box-sizing: border-box;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    height: fit-content;
    z-index: 10;

    .iconContainer {
      height: 44px;
      border-bottom: 1px solid $kite-gray-2;
      position: relative;
      bottom: 50%;

      .iconButton {
        text-align: left;
        line-height: 24px;

        .iconLabel {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: $kite-black;
        }
      }

      .enabled {
        background-color: $kite-gray-1;
        border-bottom: 1px solid $kite-gray-2;
      }
    }

    .hideOnDesktop {
      display: none;

      @include mobile {
        display: block;
      }
    }

    .trash {
      color: $kite-dark-red;

      .trash {
        color: $kite-dark-red;

        button {
          color: $kite-dark-red;

          .iconLabel {
            color: $kite-dark-red;
          }
        }
      }
    }
  }

  .leftButtons {
    display: flex;
    flex-direction: row;
    gap: 25px;

    .sendContainer {
      .send {
        width: 160px;

        @include mobile {
          width: 60vw;
          max-width: 200px;
        }
      }
    }

    .centerButtonList {
      display: flex;
      gap: 26px;

      @include mobile {
        display: none;
      }

      .centerButtons {
        width: 102px;
        text-align: left;
        padding: 0px;
        padding-bottom: 5px;

        span {
          display: inline-block;
          margin-bottom: -1px;
          margin-right: 8px;
        }
      }

      .draftLoading {
        padding-top: 12px;
      }

      .discard {
        color: $kite-dark-red;
      }
    }
    .centerButtonList {
      @media only screen and (max-width: 616px) {
        display: none;
      }
    }
  }

  .divider {
    align-self: center;
    height: 26px;
    border-right: 1px solid $kite-gray-2;
    margin: 0px 10px;

    @include mobile {
      display: none;
    }
  }

  .rightButtons {
    .rightButtonList {
      margin-left: 16px;
      display: flex;
      gap: 36px;

      @include mobile {
        margin-left: 8px;
        gap: 4px;
      }

      .icons {
        width: 20px;
        padding: 0px;
      }

      .desktopIcons {
        @include mobile {
          display: none;
        }
      }

      .kebabMenuMobile {
        display: none;
        @include mobile {
          display: block;
        }
      }

      .kebabMenuDesktop {
        @include mobile {
          display: none;
        }
      }
    }

    .divider {
      @extend .divider;
      display: block;
    }

    .selected {
      color: $kite-gray-30;
    }

    .mobileIcons {
      display: none;

      @include mobile {
        display: block;
        padding: 0px 4px;
      }

      .composeOptionsButton {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          margin-right: 8px;
        }

        p {
          margin: 0;
        }
      }
    }

    .redIcon {
      color: $kite-dark-red;
    }

    .boldFont {
      p {
        font-weight: 500;
      }
    }
  }
}
