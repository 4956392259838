@import 'styles/common.scss';

.buttonGroupContainer {
  @include wh(100%);
  display: flex;
  gap: 24px;

  @include mobile {
    flex-wrap: wrap;
  }

  @include mobile {
    justify-content: center;
  }

  .saveButton {
    width: 200px;
    min-height: 44px;

    @include mobile {
      width: 292px;
    }

    .group {
      .icon {
        height: 19px;
        width: 19px;
      }

      .text {
        line-height: 14px;
        vertical-align: text-top;
      }
    }
  }

  .cancelButton {
    display: inline-block;
    width: 200px;
    min-height: 44px;

    @include mobile {
      width: 292px;
    }
  }

  .delete {
    background-color: $kite-red-20;
  }

  .success {
    color: $kite-white;
    width: 200px;
    background-color: $kite-green-20;

    @include mobile {
      width: 292px;
    }
  }
}
