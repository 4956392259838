@import 'styles/common.scss';

.mainPage {
  height: 100vh;
  width: 100%;
  background-color: #5f7d90;

  .contentWrapper {
    position: relative;
    margin: auto;
    width: 100%;
    height: 100%;
    box-shadow: 5px 5px 15px #00000069;
    display: flex;
    flex-direction: column;
    background-color: $kite-gray-1;

    .skipButton {
      position: absolute;
      right: 32px;
      top: 24px;
      width: fit-content;
      z-index: 100;
    }

    .imageContainer {
      filter: drop-shadow(0px 0px 9px rgba(0, 0, 0, 0.25));
      flex-grow: 1;
      display: flex;
      justify-content: center;
      padding-top: 75px;

      .image {
        min-width: 205px;
        max-width: 292px;
      }
    }

    .dialogContainer {
      width: 100%;
      border-top: 1px solid $kite-gray-2;

      .dialogCard {
        min-height: 347px;
        position: relative;
        border-radius: 0px;
        background-color: $kite-white;
        padding: 32px;
        border: none;
        display: flex;
        flex-direction: column;

        .hidden {
          display: none;
        }

        .header {
          font-size: 28px;
          line-height: 36px;
          font-weight: 700;
          width: 100%;

          &:focus {
            outline: none;
          }
        }

        .secondary {
          font-size: 18px;
          width: 100%;
          margin-top: 2px;
          margin-bottom: 18px;
          min-height: 60px;
          flex-grow: 1;
        }

        .pageCount {
          font-size: 14px;
          margin-top: -6px;
          text-align: center;
        }

        .exitButton {
          background-color: transparent;
          border: none;
          height: 10px;
          position: absolute;
          right: 10px;
          top: 10px;
          cursor: pointer;

          .exitIcon {
            margin-top: 5px;
            margin-right: -2px;
          }
        }

        .arrowLeft {
          right: 300px;
          top: 43px;
          border-top: 18px solid transparent;
          border-bottom: 18px solid transparent;
          position: absolute;
          border-right: 27.71px solid white;
        }

        .actionButtonsContainer {
          .actionButtons {
            display: flex;
            width: 100%;
            font-size: 16px;
            justify-content: space-between;
            margin-bottom: 32px;
            margin-left: auto;
            margin-right: auto;

            .prevButton {
              box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
              background-color: $kite-gray-1;
              width: 122px;
            }
            .nextButton {
              filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));
              width: 122px;
            }
          }

          .firstPage {
            justify-content: right;
          }
        }
      }

      .backgroundImage {
        width: 100%;
        height: 818px;
      }

      .canarySpinner {
        width: '100vw';
        height: '100vh';
        background: 'white';
        z-index: 2000;
      }
    }
  }

  .contentWrapperSmall {
    height: 818px;
  }
}
