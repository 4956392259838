@import 'styles/common.scss';

.mailLayoutContainer {
  display: flex;
  height: 100%;

  .hideMe {
    display: none;
  }

  .inboxGrid {
    height: 100%;
  }

  .navigationContainerLayout {
    display: flex;
    flex-flow: column;
  }

  .mailContentLayout {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    height: 100%;

    .mailContentLayout > :first-child {
      flex-wrap: nowrap;

      @include mobile {
        flex-wrap: wrap;
      }
    }

    .options {
      display: flex;
      height: 42px;
    }
  }

  .folderListGridCell {
    @media only screen and (max-width: 1410px) {
      min-width: 232px;
    }
  }

  .inboxGridCell {
    height: 100%;
    @media only screen and (max-width: 1410px) {
      min-width: 338px;
    }
  }

  .wideListView,
  .wideReadingPane {
    width: 83.33% !important;

    @media only screen and (max-width: 1410px) {
      flex: 1;
    }

    @include mobile {
      width: initial;
    }
  }

  .emailViewGridCell {
    @media only screen and (max-width: 1410px) {
      width: calc(100vw - 630px);
    }
  }
}

.disableListView,
.disableReadingPane {
  display: none;

  @include mobile {
    display: initial;
  }
}

.toggleReadingPane {
  position: absolute;
  right: 1460px;
  top: 77px;
}

.fullHeight {
  height: 100%;
  display: flex;
  flex-direction: column;
}
