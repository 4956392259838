@import 'styles/common.scss';

.folderList {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 16px !important;

  &.globalFolderList {
    & > .parentFolder > .listItem > .childItem {
      margin: 0;
      padding: 0;
    }
  }

  .parentFolder {
    padding: 0;
    margin: 0;

    .listItem {
      position: relative;
      list-style: none;
      padding: 0;
      margin: 0;

      .childItem {
        margin-bottom: 0;
        padding-left: var(--kite-spacing-element-xs);

        .folderItemWrapper {
          display: flex;
          align-items: center;
          padding-right: 16px;
          position: relative;

          &.selected {
            border-left: 4px solid $kite-blue-2;
            background-color: $kite-light-blue-2;
          }

          &:hover {
            background-color: $kite-gray-1;
            color: $kite-blue-2;
          }

          .emptyTrashButton {
            padding: 0;
            font-weight: 400;
            width: fit-content;
            left: 90px;

            @include mobile {
              left: 100px;
            }

            @media (min-width: 1500px) {
              left: 100px;
            }
          }

          .folderCount {
            float: right;
            color: $kite-gray-3;

            &.selected {
              color: $kite-black;
            }
          }
        }
      }

      .noPadding {
        padding-left: 0;
      }

      .listItemWithKebab {
        display: flex;
        align-items: center;
      }

      .folderTab {
        text-decoration: none;
        display: block;
        padding: 8px 16px;

        .text {
          margin-left: 8px;
          font-size: 16px;
          color: $kite-dark-blue-3;
          line-height: 26px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-decoration: none;
          padding-right: 8px;
        }
      }

      .customFolderTab {
        display: flex;
        width: 100%;
        align-items: center;

        &:focus-visible {
          outline-offset: -4px;
        }

        .folderIcon {
          min-width: 16px !important;
        }
      }
    }
  }
}

.hover {
  background-color: $kite-gray-1;
}
