@import 'styles/common.scss';

.kiteSwitchOverride {
  --kite-switch-label-margin-right: 10px;
}

.emailFormat {
  .card {
    padding: 16px;
    height: fit-content;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    border-radius: 0;
  }

  .title {
    @include flex(row, space-between);
    @extend .kiteSwitchOverride;
    margin-bottom: 10px;
  }

  .form {
    position: relative;

    .formGroupList {
      list-style: none;
      margin: 0;
      padding: 0;

      > li {
        list-style: none;
        margin: 0;
        padding: 16px 0;
        position: relative;
        border-bottom: 1px solid $kite-gray-2;

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }

        .label {
          line-height: 26px;
        }

        .description {
          color: $kite-gray-3;
          font-size: 14px;
          line-height: 20px;
        }

        .switch {
          margin: 0;
        }
      }
    }
  }
}
