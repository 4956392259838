@import 'styles/common.scss';

.mobileNavLinkGroup {
  @include flex(column);
  justify-content: space-between;
  flex-direction: column;

  @include mobile {
    height: 100%;
    width: 100%;
  }

  .navigationList {
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 16rem;

    li {
      list-style: none;
      padding: 0;
      margin: 0;

      .mobileNavLink {
        display: block;
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        text-decoration: none;
        padding: 8px 12px;
        --color: $kite-black;
        line-height: 2.1;

        span {
          justify-content: left;
        }

        &.active {
          font-weight: 500;
        }
      }
    }
  }

  .titleOverlay {
    top: 63px;
    left: 21px;
    padding-top: 18px;
    height: 59px;
    position: absolute;
    font-size: 22px;
    font-weight: 500;
    text-align: left;
    margin-top: 25px;
    padding-left: 14px;
    padding-right: 185px;
    z-index: 1080;
    --color: $kite-black;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }

    @media only screen and (max-width: 599px) {
      top: 75px;
      left: 15px;
      padding-top: 5px;
      height: 46px;
    }
  }

  .subRouteContainer {
    margin-top: 9px;
    margin-bottom: 5px;
    background-color: $kite-white;
  }

  .subNavLinkContainer {
    @include wh(275px, 40px);
    @include flex(row, flex-start, center);
    margin-left: -16px;
    padding-bottom: 20px;
    margin-top: 10px;
    background: $kite-white;
    position: relative;

    &:hover {
      cursor: pointer;
    }

    .subNavLinkIcon {
      margin-top: 2px;
    }

    .subNavLinkText {
      padding-left: 10px;
      text-decoration: none;
      color: $kite-black;
    }

    .subNavLinkCountLabel {
      position: absolute;
      right: 13px;
      font-size: 14px;
      color: $kite-gray-3;
    }

    @media only screen and (max-width: 599px) {
      margin-left: -8px;
    }

    .searchBar {
      @include wh(448px, 44px);
      background-color: red;
    }
  }
}

// TODO - refactor so this isn't needed to be important
.accordionNavLinkTitle li button {
  @include wh(320px, 58px);
  font-size: 22px !important;
  padding-left: 7px !important;
  font-weight: 500 !important;
  background-color: $kite-gray-1;

  @media only screen and (max-width: 599px) {
    width: 328px;
  }
}

.accordionNavLinkTitle li div {
  padding-bottom: 0px !important;
}
