@import '/src/styles/common.scss';

$lightningYellow: #f9d448;
$mapPointColor: #56bb3f;
$chatColor: #5db8fc;
$footerBackground: #001019;
$textColor: #ffffff;
$columnHeaderBoarder: #2e73ca;

.footer {
  background-color: $footerBackground;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $textColor;
  font-weight: 400;

  &.loggedIn {
    padding-top: 0;
    bottom: 0;

    @include mobile {
      position: initial;
    }

    .pageFooter {
      width: 100%;
    }
  }

  &.loggedOut {
    padding-top: 24px;
    overflow: hidden;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline $textColor solid;
    color: $textColor;
  }

  .srOnly {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  .footerColumns {
    display: flex;
    justify-content: center;

    .columnHeader {
      border-top: 2px solid $columnHeaderBoarder;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      padding-top: 16px;
      padding-bottom: 8px;
      margin-bottom: 8px;
    }

    .column {
      margin: 0 12px;
    }

    .linkColumn {
      @media only screen and (min-width: 960px) {
        display: block;
      }
      max-width: 184px;
      display: none;
    }

    .socialMediaColumn {
      display: flex;
      justify-content: center;
      width: fit-content;
      padding-top: 24px;
    }

    .iconLink {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
    }

    .linkIconKiteOverride {
      height: 24px !important;
      width: 24px !important;
    }

    .linkIcon {
      @extend .linkIconKiteOverride;
      margin-right: 7px;
    }

    .lightning svg {
      fill: $lightningYellow;
    }

    .mapPoint svg {
      fill: $mapPointColor;
    }

    .chat svg {
      fill: $chatColor;
    }

    .socialIconsContainer {
      display: flex;
      flex-wrap: nowrap;
    }

    .socialMediaIcon {
      margin-right: 20px;

      .iconSizeKiteOverride {
        width: 36px !important;
        height: 36px !important;
      }
    }

    .socialMediaIcon:last-of-type {
      margin-right: 0;
    }

    .columnSpace {
      padding-right: 4.8px;
      padding-left: 4.8px;
    }

    .content {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }

  .legal {
    @include mobile {
      max-width: 100vw;
    }

    .legalDivider {
      content: ' ';
      display: block;
      width: 100vw;
      border: 0.5px solid $textColor;
      opacity: 0.275;
      margin-bottom: 30px;
    }

    .linksContainer {
      margin: auto;
    }

    .logo {
      height: 36px;
      width: 114px;
      fill: $textColor;
      float: none;
      display: block;
      margin: 0 auto 24px;

      @media only screen and (min-width: 1024px) {
        float: left;
        margin-right: 32px;
        margin-bottom: 50px;
      }
    }

    .links {
      font-size: 14px;
      padding: 0 32px;
      margin: auto;
      text-align: center;

      @include mobile {
        padding: 0px;
      }

      a:visited {
        color: $kite-white;
      }

      &.loggedOut {
        max-width: 82ch;
        @media only screen and (min-width: 1024px) {
          text-align: start;
        }

        .verticalSeparator {
          display: none;

          @media only screen and (min-width: 1024px) {
            display: inline;
          }
        }

        .linkElement {
          display: block;

          @media only screen and (min-width: 1024px) {
            display: inline;
          }
        }
      }
    }

    .linkElement {
      display: inline;
    }

    .verticalSeparator {
      height: 1em;
      border-left: 1px solid $textColor;
      vertical-align: middle;
      margin: 0 0.5em;
    }
  }
}
