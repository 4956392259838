@import 'styles/common.scss';

.contactViewContainer {
  height: 100%;
  display: flex;
  flex-direction: column;

  @include mobile {
    height: 100%;
  }

  @include mobile {
    width: 100%;
    padding: 0;
    overflow-x: hidden;
  }

  &.hideContactView {
    display: none;
    @include mobile {
      display: block;
    }
  }

  .contactViewDetails {
    height: 100%;
    padding-right: 8px;

    @include mobile {
      padding: 0px;
    }

    .progressSpinnerContainer {
      height: 100%;
      position: relative;

      @include mobile {
        height: 70vh;
      }
    }
  }

  .simpleBar {
    word-wrap: break-word;
    height: 100%;

    @include mobile {
      max-height: initial;
    }
  }

  .selectAContactPlaceholder {
    @include flex(column, center, center);
    @include marLR(auto);
    max-width: 260px;
    text-align: center;
    height: 100%;

    .placeholderIcon {
      margin-bottom: 16px;
    }
  }

  .contactCard {
    padding: 0;
    height: 100%;
    border-radius: 0px;
    display: flex;
    flex-direction: column;

    @include mobile {
      margin-right: 0;
      height: 100%;
    }

    .contactSection {
      border-bottom: 1px solid $kite-gray-2;
      padding: 16px;

      &.displayNameSection {
        border: none;
        display: flex;
        justify-content: space-between;

        @include mobile {
          flex-direction: column;
        }

        .displayNameHeader {
          display: flex;
          flex-direction: column;

          .headerText {
            padding-bottom: 8px;
          }
        }
      }

      .labelChip {
        padding-bottom: 0;
      }

      .row {
        display: flex;
        width: 100%;

        &.labelRow {
          padding-bottom: 16px;
        }

        .columnOne {
          width: 32px;
        }

        .columnTwo {
          padding-left: 16px;
          width: 100%;
          overflow: hidden;
        }

        .labelHeader {
          font-size: 20px;
          font-weight: 400;
        }

        .fieldLabel {
          color: $kite-gray-3;
        }

        .fieldValue {
          margin-right: 16px;
          margin-left: 16px;
        }

        .fieldValueEmail {
          margin-right: 16px;
        }

        .fieldLabelDefault {
          color: $kite-gray-3;
          white-space: nowrap;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            list-style: none;
            margin: 0;
            padding: 0 0 8px;

            &:last-child {
              padding: 0;
            }
          }
        }
      }
    }
  }

  .noneSelected {
    margin-top: 60px;
  }

  .selectedContacts {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    .selectedContactsDescription {
      position: absolute;
      top: 70%;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;

    @include mobile {
      .closeContactView {
        display: none;
      }
    }

    .headerText {
      font-size: 20px;
      line-height: 26px;
      font-weight: 500;
    }

    .floatRight {
      float: right;
    }
  }

  .subHeader {
    display: flex;
    justify-content: flex-start;

    @include mobile {
      :not(:first-child) {
        display: none !important;
      }
    }

    .headerSubTitle {
      font-size: 14px;
      line-height: 20px;
    }

    .headerSubTitleSeparator {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-right: 8px;
      padding-left: 8px;
      &:after {
        content: '\2022';
        position: absolute;
      }
    }
  }
}
