@import '../../styles/common.scss';

.settingLayoutContainer {
  display: flex;
  height: 100%;

  .settingContentLayout {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;

    @include mobile {
      height: 100%;
    }

    .settingGrid {
      height: 100%;
      flex-wrap: nowrap;
      width: 100%;

      @include mobile {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .settingMenuCell {
          width: 100%;
        }
      }

      .settingMenuCell {
        height: 100%;
      }

      .settingContentCell {
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        width: calc(100% - 240px);

        @include mobile {
          width: 100%;
        }

        .footerContainer {
          width: 100%;
        }
      }
    }

    .settingContent {
      padding: 16px 0 0 16px;
      max-width: 664px;
      flex-grow: 1;

      @include mobile {
        padding: 0;
        max-width: none;
      }
    }
  }
}
