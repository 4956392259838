@import 'styles/common.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;

  .dot {
    background: $kite-gray-2;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }

  .currPage {
    background: $kite-gray-3;
  }
}
