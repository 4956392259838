@import 'styles/common.scss';

.kiteSwitchOverride {
  --kite-switch-label-margin-right: 10px;
}

.blockSenderContainer {
  .card {
    padding: 16px;
    position: relative;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    border-radius: 0;

    @include mobile {
      width: 100vw;
    }

    .pageLevelError {
      margin-top: 32px;
    }

    .lists {
      .noResultText {
        text-align: center;
        padding: 24px 0px 8px;
        margin: 0px;
      }

      .blockSenderList {
        list-style: none;
        padding: 0;
        margin-left: 12px;

        > li {
          list-style: none;
          margin: 0;
          padding: 16px 0;
          position: relative;
          border-bottom: 1px solid $kite-gray-2;

          .emailSection {
            display: flex;
            gap: 8px;
            line-height: 26px;
            align-items: center;
            padding: 0px 0px 0px 8px;

            .icon {
              min-width: 20px;
              color: $kite-dark-blue-1;
            }

            .draftStatus {
              min-width: fit-content;
              color: $kite-gray-3;
            }

            .emailText {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &.isDeleted {
                text-decoration: line-through;
                color: $kite-red;
              }

              &.isNew {
                color: $kite-dark-green;
              }
            }
          }

          .iconSection {
            color: $kite-blue-2;
            display: flex;
            padding: 0px;

            @include mobile {
              justify-content: flex-end;
            }

            .deleteSender {
              padding: 0;
            }

            .actionButton {
              height: 21px;
              width: fit-content;

              @include mobile {
                padding: 0;
              }

              .trashIcon {
                color: $kite-red;
              }
            }
          }

          .label {
            line-height: 26px;
          }
        }
      }
    }
  }

  .title {
    @include flex(row, space-between);
    @extend .kiteSwitchOverride;
    margin-bottom: 10px;
  }

  .description {
    font-size: 14px;
  }

  .form {
    margin-top: 32px;
    margin-bottom: 8px;

    .maxInputHint {
      color: $kite-gray-3;
      font-size: 14px;
    }

    .searchButtonContainer {
      position: relative;
      max-width: 416px;

      .searchBarInput {
        @include wh(100%, 44px);
        min-width: 320px;
        border: 1px solid $kite-gray-2;
        padding-left: 56px;

        @include mobile {
          min-width: unset;
        }

        @include placeholder {
          @include placeholder-style;
        }
      }

      .searchIcon {
        position: absolute;
        left: 16px;
        top: 10px;

        @include mobile {
          left: 18px;
        }
      }
    }

    .addButton {
      width: fit-content;
      padding: 0px;
    }
  }

  .emptyList {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 32px;
  }

  .blockSenderContainer {
    position: relative;
  }
}
