@import 'styles/common.scss';

.kiteOverride {
  --text-input-max-width: 100%;
  --kite-text-area-max-width: 100%;
}

.header {
  font-size: 20px;
  margin-top: 16px;
  font-weight: bold;
}

.filterForm {
  position: relative;
  height: 100%;
  //width: 83.33%;
  overflow: auto;
  padding-bottom: 40px;

  @include mobile {
    width: 100vw;
  }

  & > :global(.kite-button--borderless) {
    padding-left: 0px;

    @include mobile {
      padding-left: 16px;
    }
  }

  .backButton {
    @include flexCenter(row);
    justify-content: left;
    padding: 16px;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .newFilter {
    //margin-left: 16px;
    //width: 664px;

    .alert {
      margin-bottom: 24px;
    }

    .formFields {
      border-bottom: 1px solid $kite-gray-2;
    }

    .fieldSection {
      @include padTB(24px);
      width: 100%;
      border-top: 1px solid $kite-gray-2;

      .fieldContainer {
        @include flex(row, initial, baseline);

        .xButton {
          background: none;
          cursor: pointer;
          border: none;
          color: $kite-blue-2;
          padding-top: 6px;
          margin-top: 36px;
          align-self: flex-start;
        }

        .destinationFolderContainer {
          position: relative;
        }
      }

      .fieldContainerHeader {
        margin-top: 16px;
        margin-bottom: 16px;
        font-size: 20px;
        font-weight: bold;
      }

      .fieldIcon {
        align-self: flex-start;
        margin-top: 42px;
      }

      .fieldRadio {
        align-self: flex-start;
        margin-top: 42px;
        margin-left: 16px;
        text-transform: capitalize;
      }
      .addButton {
        @extend .backButton;
        margin-left: 16px;
      }
      .address {
        &:not(:first-of-type) {
          margin-top: 16px;
        }
      }

      .addFieldButton {
        padding: 0;
        margin-top: 16px;
      }

      .addFieldDescription {
        color: $kite-gray-3;
        font-size: 14px;
      }
    }

    .halfField {
      width: 250px;

      &:not(&:first-of-type) {
        margin-left: 16px;
      }
    }

    .fullField {
      @extend .kiteOverride;
      width: 516px;
      textarea {
        margin: 0;
      }
    }

    .checkboxField,
    .inlineField {
      display: flex;
      align-items: center;
      .inlineSelect {
        display: inline-flex;
      }
    }

    .inlineField {
      & > * {
        margin: 6px;
      }
    }

    .radioButtonDetails {
      font-size: 14px;
      margin-left: 28px;
    }

    .fieldContainerWithRadio {
      :global(.kite-radio-group__legend) {
        font-weight: bold;
      }
      :global(.kite-radio) {
        margin-bottom: 0;
      }
    }

    .buttonBar {
      padding-top: 32px;

      .showMoreButton {
        margin-bottom: 32px;
      }
    }
  }
}
