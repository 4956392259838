@import '../../styles/common.scss';

.modalContainer {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.backgroundCard {
  z-index: 1073;
  position: relative;
  width: 100%;
  height: 100%;
}

.content {
  z-index: 1075;
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0.9;
}

.backgroundImg {
  z-index: 1073;
  position: absolute;
  bottom: 0;
  left: 0;
  filter: contrast(80%);
  opacity: 0.9;
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 1) 30%, transparent 100%);
}

.xIconButton {
  background-color: transparent;
  border: none;

  :hover {
    cursor: pointer;
    color: $kite-blue-3;
  }

  .kiteIconSizeOverride {
    height: 30px !important;
    width: 30px !important;
  }

  .xIcon {
    @extend .kiteIconSizeOverride;
  }
}

.openCard {
  animation: slidein 0.5s;
  background-position: left right;
  animation-fill-mode: forwards;
}

.closeCard {
  animation: slideout 0.5s;
  background-position: right left;
  animation-fill-mode: backwards;
}

// Animation

@keyframes slidein {
  from {
    left: -400px;
  }
  to {
    left: 0px;
  }
}

@keyframes slideout {
  from {
    left: 0;
  }
  to {
    left: -400px;
  }
}
