@import 'styles/common.scss';

.kiteSwitchOverride {
  --kite-switch-label-margin-right: 10px;
}

.editorCard {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 16px 16px 16px;
  margin-top: 16px;
  border-radius: 0;

  .signatureText {
    margin: 0;
  }

  .textEditorGroup {
    max-height: 100%;
    margin-bottom: 32px;
  }

  .signatureAlert {
    margin-bottom: 32px;
  }

  .buttonGroup {
    display: flex;
    margin-top: 32px;
    .saveButton {
      width: 200px;
      min-height: 44px;

      .group {
        .icon {
          height: 19px;
          width: 19px;
        }

        .text {
          line-height: 14px;
          vertical-align: text-top;
        }
      }
    }

    .cancelButton {
      display: inline-block;
      width: 200px;
      min-height: 44px;
      margin-left: 16px;
    }

    .success {
      background-color: $kite-green-20;
    }
  }
}
