@import 'styles/common.scss';

.kiteSwitchOverride {
  --kite-switch-label-margin-right: 10px;
}

.paginationContainer {
  .paginationBar {
    flex-direction: column-reverse;
    flex-wrap: wrap;
    min-height: 80px;

    @include mobile {
      min-height: initial;
      & :global(.kite-pagination__page-total) {
        display: unset;
      }

      & :global(.kite-pagination__right) {
        display: none;
      }
    }
  }
}
