@import 'styles/common.scss';

.actionIcons {
  .attachmentButton {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .attachmentName {
    color: $kite-black;
    font-weight: normal;
    padding-left: 16px;
  }
  .hiddenAttachmentIcon {
    display: none;
  }
}
