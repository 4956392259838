@import 'styles/common.scss';

.SectionTab {
  display: flex;
  align-items: center;
  list-style: none;
  width: 100%;
  border-left: $kite-white 4px solid;

  &:hover {
    background-color: $kite-gray-1;
  }

  &.active {
    background: $kite-light-blue-2;
    border-left: $kite-blue-2 4px solid;
  }

  .SectionTabLink {
    @include flex(row, space-between, center);
    @include marLR(16px);
    box-sizing: border-box;
    width: 100%;
    text-decoration: none;
    color: $kite-black;
    padding: 12px 16px;
    margin: 0px;

    &:focus-visible {
      outline-offset: -4px;
    }

    .PreviewDisplay {
      color: $kite-gray-3;
      text-transform: capitalize;

      &.On {
        color: $kite-dark-green;
      }
    }
  }
}
