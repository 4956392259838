@import 'styles/common.scss';

:global(.kite-menu--contextual-label.kite-menu--top) {
  :global(.kite-menu__list) {
    top: 40px;
    left: 0px;
    width: 196px;

    @media only screen and (max-width: 1880px) {
      left: -120px;
    }
  }
}

.sortOptionsContainer {
  .listItem {
    position: relative;
    padding-left: 24px;

    .checkIcon {
      position: absolute;
      top: 12px;
      left: 4px;
    }

    .selected {
      font-weight: 700;
    }
  }

  :global(.kite-menu--contextual-label) {
    top: 0px;
    left: 8px;
    background-color: transparent;
    &:active {
      background-color: transparent;
    }
    :global(.kite-menu__trigger) {
      color: $kite-blue-2;
      &:hover {
        background-color: transparent;
      }
      &:active {
        background-color: transparent;
      }

      :global(.kite-menu__trigger-label) {
        color: $kite-blue-2;
      }

      :global(.kite-icon svg) {
        fill: $kite-blue-2;
      }
    }
  }

  :global(.kite-menu--open .kite-menu__trigger) {
    &:hover {
      background-color: transparent;
    }
    background-color: transparent;

    :global(.kite-icon svg) {
      fill: $kite-blue-2;
    }
  }
}

.disabled {
  pointer-events: none;

  :global(.kite-menu--contextual-label) {
    top: 0px;
    left: 8px;
    opacity: 50%;

    :global(.kite-menu__trigger) {
      color: $kite-gray-3;

      :global(.kite-menu__trigger-label) {
        color: $kite-gray-3;
      }

      :global(.kite-icon svg) {
        fill: $kite-gray-3;
      }
    }
  }
}
