@import 'styles/common.scss';

.container {
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .hidden {
    display: none;
  }

  .item {
    position: absolute;
    top: -6px;
    filter: drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.18));
    padding-left: 6px;

    .iconBackground {
      position: relative;
      top: 20px;
      left: 2px;
      height: 17px;
      width: 19px;
      background-color: $kite-white;
      filter: drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.18));
    }

    .emailCount {
      position: relative;
      width: 18px;
      height: 18px;
      background-color: $kite-dark-red;
      border-radius: 500px;
      top: -14px;
      right: -12px;
      color: $kite-white;
      font-weight: 500;
      font-size: 14px;
      filter: drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.18));
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .cancel {
      position: relative;
      left: -10px;
      top: 10px;
      background-color: none;
      filter: drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.18));
    }

    .wide {
      width: 21px;
    }

    .wider {
      width: 29px;
    }
  }
}
