@import 'styles/common.scss';

.kiteSwitchOverride {
  --kite-switch-label-margin-right: 10px;
}

.displayNameContainer {
  @include wh(100%);
  max-width: 664px;

  @include mobile {
    max-width: none;
  }

  .displayNameCard {
    border-radius: 0;
    padding: 16px;
    height: fit-content;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;

    .title {
      @include flex(row, space-between);
      @extend .kiteSwitchOverride;
      margin-bottom: 10px;
      & > * {
        margin: 0;
      }
    }

    .email {
      margin-top: 15px;
    }

    .changePasswordLink {
      display: block;
      @extend .email;
    }

    .displayNameDescription {
      font-size: 14px;
    }

    .displayNameInput {
      margin-top: 15px;
      margin-bottom: 32px;
    }

    .alert {
      margin-top: 24px;
    }
  }

  .hidden {
    display: none;
    .buttonsGroup {
      .buttons {
        margin-right: 16px;
      }
    }
  }
}
