@import 'styles/common.scss';

.messageBody {
  min-height: 55vh;
  max-height: 70vh;
  width: 100%;
  margin-bottom: 0;

  @include mobile {
    min-height: 55vh !important;
  }

  p {
    margin: 0;
  }
}

.rawContainer {
  white-space: pre-line;
}
