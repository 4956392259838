@import 'styles/common.scss';

$kite-blue-20: #002747;
$kite-gray-30: #63738a;

.chevronContainer {
  display: flex;
  align-self: flex-end;
  margin-bottom: 4px;
  position: relative;

  @include mobile {
    max-width: 100vw;
    padding: 12px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
  }

  .chevronButton {
    @include wh(40px, 56px);
    background-color: $kite-dark-blue-1;
    border: none;
    position: relative;
    border-radius: 4px;
    cursor: pointer;

    .chevronPopover {
      font-size: 14px;
      color: $kite-blue-1;
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;
      background-color: $kite-white;
      @include bor($kite-blue-1);
      @include wh(20px, 20px);
      border-radius: 50%;
      top: -8px;
      left: -8px;

      button {
        display: none;
      }

      :nth-child(2) {
        :first-child {
          :nth-child(2) {
            display: none;
          }
        }
      }
    }

    .chevronIcon {
      color: $kite-white;
      display: flex;
      margin: auto;
      transform: rotate(90deg);
    }
  }

  .dropDownContainer {
    background-color: #002747;
    z-index: 2100;
    position: absolute;
    bottom: 67px;
    width: 288px;
    border: 1px solid #63738a;
    box-sizing: border-box;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: auto;

    @include mobile {
      left: 60px;
      width: 300px;
    }

    @media only screen and (max-width: 320px) {
      left: 28px;
      width: 300px;
    }

    ul,
    li {
      list-style: none;
      margin: 0px;
      padding: 0px;
    }

    ul > li.dropdownListItem {
      &.selected {
        font-weight: 700;
      }

      background-color: transparent;
      width: 100%;
      padding: 10px 24px;
      color: $kite-white;
      border-bottom: 1px solid $kite-gray-30;
      position: relative;
      cursor: pointer;

      .checkIcon {
        position: absolute;
        left: 5px;
        margin-top: 5px;
      }
    }
  }
}

.marginRight {
  margin-right: 16px;

  @include mobile {
    margin-right: 0px;
  }
}

.hideChevronMobile {
  @include mobile {
    display: none;
  }
}
