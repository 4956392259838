@import 'styles/common.scss';

.backButton {
  @include flexCenter(row);

  p {
    margin: 0;
    margin-left: -10px;
  }
}
