@import 'mixins', 'vars', 'node_modules/@kite/colors/_variables.scss', 'node_modules/@kite/shared/_mixins.scss';

a,
a--visited,
a:visited {
  color: #001019;
}

html,
body,
#root {
  height: 100%;
}

.overlay {
  @include wh(100vw, 100vh);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation-fill-mode: forwards;
}

.overlay-center {
  @extend .overlay;
  @include flexCenter();
}

.overlayFadeIn {
  animation: fadeIn 0.5s;
}

.overlayFadeOut {
  animation: fadeOut 0.5s;
}

// Animation

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.scrollbar-container {
  max-width: 100%;
}
