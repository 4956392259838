@import 'styles/common.scss';

.timeZoneContainer {
  @include wh(100%);
  max-width: 664px;

  @include mobile {
    width: 100%;
    max-width: none;
  }

  .timeZoneCard {
    padding: 16px;
    height: fit-content;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    border-radius: 0;

    .title {
      margin-bottom: 10px;
    }

    .description {
      font-size: 14px;
    }

    .alert {
      margin-top: 24px;
    }

    .form {
      padding-top: 24px;
      padding-bottom: 32px;
    }
  }
}
