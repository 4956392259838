@import '../../../styles/common.scss';

.loginHeader {
  border-bottom: 1px solid $kite-gray-2;
  background-color: $kite-white;
  padding: 0 25px;

  .headerCell {
    height: 70px;
    display: flex;
    flex: 0 0 calc(100% / 3);
    min-width: none;
    padding: 25px 0;
  }

  .headerLogo {
    justify-content: center;
  }

  .disappearingElement {
    display: none;

    @media only screen and (min-width: 1024px) {
      display: flex;
    }
  }

  .headerLinks {
    justify-content: flex-end;
    @extend .disappearingElement;
  }

  .loginHeaderMenu {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .headerMenuText {
    margin-left: 10px;
    @extend .disappearingElement;
  }

  a {
    &,
    &:hover {
      color: $kite-black;
      text-decoration: none;
    }
  }
}
