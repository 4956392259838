@import 'styles/common.scss';

$kite-blue-20: #002747;

.modalContainer {
  @include wh(100%);
  overflow-y: auto;
  overflow-x: hidden;
}

.composeMessageContainer {
  display: flex;
  justify-content: flex-end;
  height: fit-content;
  position: absolute;
  bottom: 0px;
  right: 16px;
  width: 616px;
  z-index: 2;

  @include mobile {
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    padding-top: 0;
    margin-left: 0;
  }
}

.maximized {
  @include wh(100%);
  padding-left: 0px;
  top: 0;
  left: 0;
  position: relative;
  margin: 0;
  padding: 0;
  justify-content: flex-start;

  .composeListContainer {
    width: 100%;
  }
}
