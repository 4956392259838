@import 'styles/common.scss';

.layoutContainer,
.layoutContainerWithSearch {
  display: flex;
  overflow: hidden;
  flex-grow: 1;

  @include mobile {
    height: 100%;
    overflow: unset;
  }

  .hideMe {
    display: none !important;
  }

  .contentLayout {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: calc(100% - 60px);
    @include mobile {
      height: 100%;
    }
  }

  .composeButton {
    position: fixed;
    bottom: 25px;
    right: 25px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    z-index: 3;
    :global(.kite-icon) {
      margin-right: 0 !important;
    }
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
      0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  }
}

.screenReaderHeading {
  visibility: hidden;
  position: absolute;
}
