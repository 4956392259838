@import 'styles/common.scss';

.resyncModal {
  .headerIconContainer {
    display: flex;
    gap: 8px;
    align-items: center;

    h5 {
      margin: 0;
    }
  }

  .errorDescription {
    margin-top: 24px;
  }
}

.saveCancel {
  display: flex;
  gap: 24px;
}

.ok {
  width: 244px;
}

.marginTop {
  margin-top: 66px;
}
