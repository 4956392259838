@import '../../../styles/common.scss';

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 85px;
  align-items: center;
  background-color: $kite-white;
  box-shadow: inset 0px -1px 0px $kite-gray-2;
  width: 100%;
}
