@import '../../styles/common.scss';

.contactViewContainer {
  height: 100%;
  @include flex(column, initial, flex-start);
  background-color: transparent;
  text-decoration: none;

  .contactOptionsContainer {
    @include wh(100%, 58px);
    height: 58px;
    color: $kite-black;
  }

  .contactCardContainer {
    @include wh(100%);
    @include flex(column, initial, center);
    overflow: hidden;

    .contactViewCard {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;

      @include mobile {
        padding: 0;
        max-width: 100vw;
      }

      .headerContainer {
        padding: 0px 8px;

        @include mobile {
          padding: 0px;
        }

        .contactHeaderLabel {
          margin: 0;
          background-color: $kite-white;
          border: 1px solid $kite-gray-2;
          font-weight: 500;
          font-size: 20px;
          line-height: 26px;
          padding: 10px 24px;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }

  .paginationContainer {
    @include flexCenter();
    align-self: flex-end;
  }
}
