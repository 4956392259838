@import 'styles/common.scss';

.noContactWarning {
  padding: 20px;
}

.contactPrintContainer {
  padding: 16px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      border-bottom: 1px solid $kite-black;
      padding: 8px 0;

      .divider {
        border-top: 1px solid $kite-gray-2;
        margin: 8px 0;
      }

      .labelRow {
        margin-bottom: 4px;

        &.nameLabel {
          font-weight: 700;
        }

        .label {
          min-width: 200px;
          display: inline-block;
        }
      }
    }
  }
}
