@import 'styles/common.scss';

.loading {
  @include wh(100vw, 100vh);
  background: $kite-white;
  z-index: 2000;
}

.contactList {
  height: 100%;
  width: 100%;
  padding: 0px 8px;

  @include mobile {
    padding: 0px;
  }

  .emptyListPlaceHolder {
    @include wh(100%);
    @include flexCenter(column);
    text-align: center;
    background-color: $kite-white;

    .emptyListPlaceHolderIcon {
      margin-bottom: 16px;
    }
  }

  .contactSelect {
    position: absolute;
    top: 11px;
    left: 14px;
    margin: 0px;
    user-select: none;
  }

  .simpleBar {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid $kite-gray-2;

    @include mobile {
      height: fit-content;
    }

    .flexContainer {
      display: flex;
      flex-direction: column;
      height: 100%;

      .loaderContainer {
        height: 100%;
      }
    }

    .listContainer {
      flex-grow: 1;
      border: 1px solid $kite-gray-2;
      border-top: 0;
      background-color: $kite-white;

      ul {
        padding: 0px;
        margin: 0px;
        border-bottom: 1px solid $kite-gray-2;
        margin-bottom: -1px;

        @include mobile {
          padding: 0px;
        }

        > li:last-child {
          border-bottom: none;
        }
      }

      .fullWidth {
        height: 100%;
      }
    }

    .contactWrapper {
      position: relative;
      border-bottom: 1px solid $kite-gray-2;
      padding: 8px 16px;
      background-color: $kite-white;

      &:hover {
        background-color: $kite-gray-1;
        cursor: pointer;
      }

      &.itemSelected {
        border-left: 4px solid $kite-blue-2;
        background-color: $kite-light-blue-2;
      }

      &.checked {
        background-color: $kite-light-blue-2;
      }
    }

    .contactListItem {
      list-style: none;
      text-decoration: none;
      color: $kite-black;
      display: flex;
      flex-direction: column;
      margin-bottom: 0px;

      .row {
        display: flex;
        width: 100%;

        .columnOne {
          width: 32px;
        }

        .contactNameColumn {
          margin-left: 2px;
        }

        .columnTwo {
          width: 100%;
          overflow: hidden;

          .contactWithLabelsCount {
            margin-bottom: 4px;
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            display: flex;
            flex-flow: row nowrap;

            .name {
              flex: 1 1 auto;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-left: 28px;

              a {
                text-decoration: none;
                color: $kite-black;
                font-weight: 500;
              }
            }

            .labelsCount {
              flex: 0 0 auto;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              display: block;
            }
          }

          .email {
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 4px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;

            div {
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }

        .phoneNumber {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $kite-gray-3;
          // flex: 1 1 auto;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
}

.pagination {
  width: 100%;
  margin-top: 14px;
  padding-bottom: 24px;

  @include mobile {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .kitePagination {
    flex-direction: column-reverse;
    flex-wrap: wrap;

    :first-child {
      :nth-child(2) {
        :nth-child(2) {
          padding: 3px;
        }
        :nth-child(3) {
          bottom: 16px;
          width: 60px;
        }
      }
    }

    :nth-child(2) {
      :nth-child(2) {
        :nth-child(2) {
          :nth-child(2) {
            padding-top: 4px;
            padding-bottom: 4px;
          }
          :nth-child(3) {
            bottom: 12px;
            width: 64px;
          }
        }
      }
    }
  }
}
