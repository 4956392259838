@import '../../../styles/common.scss';

.mainHeader {
  background-color: $kite-white;
  padding: 0 27px;
  height: 62px;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: $kite-white;
  box-shadow: inset 0px -1px 0px #d8dde6;
  position: relative;

  .headerGrid {
    height: 100%;

    .headerCell {
      display: flex;
      align-items: center;
      flex: 0 0 calc(100% / 3);
      min-width: none;
    }

    .headerLeftCell {
      justify-content: flex-start;
      flex: 0 0 33%;

      @media (min-width: 1436px) {
        flex: 0 0 auto;
        width: 18.6666%;
      }

      .spectrumLogoLeft {
        margin-top: 10px;

        @include mobile {
          display: none;
        }
      }
    }

    .headerMiddleCell {
      flex: 0 0 33%;
      justify-content: center;

      @media (min-width: 1436px) {
        flex: 0 0 auto;
        width: 31%;
      }

      .mobileLogoContainer {
        display: none;

        @include mobile {
          display: flex;
          padding-top: 8px;
        }
      }
    }

    .headerRightCell {
      justify-content: flex-end;
      @extend .disappearingElement;
      flex: 0 0 33%;

      @media (min-width: 1436px) {
        flex: 0 0 auto;
        width: 50.3333%;
      }

      .spanishButton {
        width: fit-content;
        color: $kite-black;
        font-weight: 400;
      }

      .verticalSeparator {
        @include verticalSeparator;
      }
    }

    .disappearingElement {
      display: none;

      @media only screen and (min-width: 1024px) {
        display: flex;
      }
    }

    .loginHeaderMenu {
      background: none;
      border: none;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .mainHeaderHamburger {
      display: none;
      @include mobile {
        display: block;
      }
    }

    .headerMenuText {
      margin-left: 10px;
      @extend .disappearingElement;
    }

    .signOutLink {
      margin-right: 3px;
      background-color: $kite-white;
      color: $kite-black;
      font-weight: 400;
      margin-left: 21px;
      width: 62px;
    }

    a {
      &,
      &:hover {
        color: $kite-black;
        text-decoration: none;
      }
    }
  }
}
