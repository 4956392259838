@import 'styles/common.scss';

.chip {
  display: flex;
  border: 1px solid $kite-gray-2;
  height: 42px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  min-width: 0;

  .chipDownload {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    border-right: 1px solid $kite-gray-2;

    .attachmentIcon {
      margin-right: 12px;
      margin-left: 12px;
      min-width: 15px;
      color: $kite-blue-2;
    }
  }

  .chipPreview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    margin-left: 12px;
    margin-right: 12px;
    overflow: hidden;

    .name {
      color: $kite-blue-2;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      margin-left: 8px;
      margin-right: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .size {
      color: #000;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-right: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
