@import 'styles/common.scss';

.modalContainer {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  .overlay {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    z-index: 1072; /* Specify a stack order in case you're using a different order for other elements */
    // cursor: pointer; /* Add a pointer on hover */
  }

  .modalOverlay {
    z-index: 1076;
  }

  .modalCard {
    height: auto;
    width: 30rem;
    max-width: 100%;
    z-index: 1080;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 48px;
    overflow-x: hidden;
    overflow-y: hidden;

    @include mobile {
      padding: 12px;
    }

    .closeButton {
      width: fit-content;
      height: fit-content;
      position: absolute;
      right: 24px;
      top: 24px;
      padding: 0;

      @include mobile {
        right: 0;
        top: 13px;
      }
    }
  }

  .wideModal {
    min-width: 30rem;
    width: 80vw;

    @include mobile {
      min-width: initial;
      width: 100vw;
    }
  }

  .canaryCard {
    width: 608px;
  }

  .addressBookCard {
    width: 860px;
    height: 652px;

    @include mobile {
      width: 100%;
      height: 90vh;
    }
  }

  .drawerCard {
    z-index: 1075;
    position: relative;
    width: 17rem;
    height: 100%;
    padding: 24px 0;
    overflow: hidden;

    .closeButton {
      width: fit-content;
      height: fit-content;
      padding-left: 16px;
      max-width: 100%;
      span {
        max-width: 100%;
      }
    }
  }
}

.openCard {
  animation: slideIn 0.5s;
  animation-fill-mode: forwards;
}

.closeCard {
  animation: slideOut 0.5s;
  animation-fill-mode: backwards;
}

// Animation
@keyframes slideIn {
  from {
    left: -17rem;
  }
  to {
    left: 0;
  }
}

@keyframes slideOut {
  from {
    left: 0;
  }
  to {
    left: -17rem;
  }
}
