@import '../../../styles/common.scss';

.textAreaContainer {
  flex: 1;

  .messageLabelContainer {
    display: flex;
    color: $kite-gray-3;
    padding: 12px 0px;
    align-items: center;
    width: calc(100% - 6px);
    align-items: center;
    justify-content: space-between;

    .label {
      color: $kite-gray-3;
      font-size: 16px;
      margin-right: 5px;
    }

    .actionIcons button {
      color: $kite-blue-2;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-self: flex-end;
      background: none;
      border: none;
      gap: 8px;
    }

    .actionIcons {
      margin-top: 4px;
      position: relative;

      .attachmentButton {
        font-weight: 400;
        height: fit-content;
        padding: 0;
        position: relative;

        .attachLabel {
          position: relative;
        }
        .icon {
          margin-right: 4px;
        }
      }

      .loadingMargin {
        top: 12px;
        left: 10px;
      }

      .hiddenAttachmentIcon {
        display: none;
      }
    }
  }

  .textEditorContainer {
    height: fit-content;
  }

  .textEditor {
    overflow: auto;

    &:focus {
      outline: none;
    }

    > div {
      display: flex;
      flex-direction: column-reverse;

      > div:nth-child(1) {
        border-radius: 4px;
        height: 52px;
        background-color: $kite-white;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        width: 580px;
        bottom: 82px;

        > span:nth-child(1) {
          width: 113px;
          display: flex;
          flex-direction: row;
          position: relative;

          &:after {
            content: '';
            height: 52px;
            border-right: 1px solid $kite-gray-2;
            margin-left: 30px;
            position: absolute;
            top: -14px;

            left: 63px;
          }
        }

        > span:nth-child(2) {
          display: flex;
          flex-direction: row;
          width: 241px;
          padding: 0px;
          position: relative;
          justify-content: center;
          align-items: center;

          &:after {
            content: '';
            height: 52px;
            border-right: 1px solid $kite-gray-2;
            margin-left: 30px;
            position: absolute;
            top: -14px;
            left: 215px;
          }
        }

        > span:nth-child(3) {
          display: flex;
          flex-direction: row;
          width: 50px;
          padding: 0px;
          justify-content: center;
          align-items: center;
          position: relative;
          margin: 0;
        }

        > span:nth-child(4) {
          display: flex;
          flex-direction: row;
          width: 241px;
          padding: 0px;
          justify-content: center;
          align-items: center;
          position: relative;
        }
      }
      /* Compose Text Area */
      div:nth-child(2) {
        max-height: 100%;
        overflow: auto;
        word-wrap: normal;
        display: flex;
        border: none;
        width: 580px;
        > div {
          width: 580px;
        }
      }
    }
  }

  .hidden {
    > div {
      > div:nth-child(1) {
        display: none;
      }
    }
  }
}

/* drop-down overrides */
// .ql-snow {
// display: block;
// /* margin-top: -1px; */
// display: flex;
// z-index: 1;
// flex-direction: column-reverse;
// position: absolute;
// bottom: 23px;
// }

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  background-color: purple;
}

.tox-tinymce {
  height: 400px;
  background-color: red;
}
