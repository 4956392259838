@import '~@kite/react-kite/dist/react-kite.css';
@import 'components/compose-input-field/styles/ReactAutoCompleteOverrides.scss';

.kite-pagination__left {
  height: 40px;
  margin-left: 0;
}

.kite-pagination__right {
  height: 40px;
}

.kite-pagination__prev,
.kite-pagination__next {
  width: 40px !important;
}

.kite-button--borderless .kite-progress,
.kite-button--secondary .kite-progress {
  --kite-progress-indicator-button-fill-color: var(--kite-color-primary);
}
.kite-button--secondary:hover .kite-progress {
  --kite-progress-indicator-button-fill-color: var(--kite-color-white);
}

.no-scroll {
  position: fixed;
  width: 100vw;
}

.ps__rail-y {
  z-index: 10;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;

  @include mobile {
    height: 100%;
  }
}

.srOnly {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

ol > li,
ul > li,
.kite-list > li {
  margin-bottom: 0;
  padding-left: 0;
}
