@import 'styles/common.scss';

.loading {
  @include wh(100vw, 100vh);
  background: 'white';
  z-index: 2000;
}

.kebabButton {
  height: 20px;
  width: 20px;
}

.folderListContainer {
  width: 100%;
  height: 100%;

  @include mobile {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .simpleBar {
    height: 100%;
    word-wrap: break-word;
    overflow-x: hidden;

    @include mobile {
      height: 100%;
    }

    ul {
      padding: 0;
      margin: 0;
    }

    .globalFolderList {
      padding-bottom: 12px;
      display: flex;
      flex-direction: column;
    }

    .customFolderList {
      border-top: 1px solid $kite-gray-2;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h2 {
          font-size: 20px;
          font-weight: 500;
          line-height: 26px;
          margin: 0;
          padding: 24px 16px;
        }

        .newFolderBtn {
          @include wh(106px, 26px);
          font-size: 16px;
          padding: 0px;
          margin-right: 16px;

          span {
            display: flex;
            span {
              font-size: 16px;
              line-height: 26px;
              margin-bottom: 5px;
              display: flex;
              gap: 10px;

              align-items: center;
              display: flex;
              flex-flow: row nowrap;
              flex-shrink: 0;
              justify-content: center;
              position: relative;
              height: 100%;
            }

            .icon {
              padding: 0;
              margin: 0px;
            }
          }
        }
      }
    }

    .itemSelected {
      border-left: 4px solid $kite-blue-2;
      background-color: $kite-light-blue-2;
      box-shadow: inset 0px -1px 0px #d8dde6;
    }
  }
}
