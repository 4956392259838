.fieldForm {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 16px 16px 16px;
  border-radius: 0;

  .alert {
    margin-top: 24px;
  }

  .field {
    margin-top: 16px;
  }

  .buttonBar {
    margin-top: 32px;
  }
}
