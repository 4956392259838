@import '../../../styles/common.scss';

.inputFieldContainer {
  display: flex;
  color: $kite-gray-3;
  padding: 12px 0px;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid $kite-gray-2;
  align-items: center;

  .label {
    margin-right: 4px;

    @include mobile {
      display: flex;
    }
  }

  .tagInputList {
    margin-right: 8px;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    overflow: hidden;
    gap: 6px 0px;

    .tag {
      color: $kite-black;
      cursor: default;
      display: inline-flex;
      align-items: center;
      height: 28px;
      border-radius: 4px;
      background-color: $kite-gray-2;
      font-weight: 400;
      font-size: 14px;
      list-style: none;
      padding: 5px 10px;
      max-width: 100%;
      margin-right: 5px;

      .tagName {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 185px;
        margin-right: 5px;
        height: auto;
      }

      button {
        display: inline-flex;
        background: transparent;
        border: none;
        color: $kite-blue-2;
        cursor: pointer;
        display: inline-flex;
        line-height: 0;
        padding: 0;
        position: relative;

        .closeIcon {
          margin-top: 5px;
        }
      }
    }
  }

  .actionIcons {
    display: flex;
    flex-direction: row;
    gap: 16px;

    .fieldOptions {
      background: none;
      cursor: pointer;
      border: none;
      margin: 0;
      padding: 0;
      height: fit-content;
      width: fit-content;

      p {
        font-size: 16px;
        color: $kite-blue-2;
        cursor: pointer;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    .xButton {
      background: none;
      cursor: pointer;
      border: none;
      color: $kite-blue-2;
      padding-top: 6px;
    }
  }
}

.tagInputList,
.inputField {
  input {
    border: none;
    width: 100%;
    height: fit-content;
    line-height: 0px;
    padding: 0px;

    &:focus {
      outline: none;
    }
  }

  ul {
    position: fixed;
    left: unset;
    top: unset;
    z-index: 1;
    > li {
      margin: 0;
    }
  }

  :global(.kite-icon) {
    fill: '#0073D1';
  }

  .maxWidth {
    width: 100%;
  }
}

.subject {
  width: 100%;
}

.addressBookButton {
  height: fit-content;
  padding: 0px;
  margin: 0px;
  font-weight: 500;
}
