@import 'styles/common.scss';

.labelContainer {
  padding: 0;
  border-radius: 0px;
  border-top: none;
  height: 100%;

  .newContactButtonSection {
    padding: 24px 16px;
  }

  .labelItemList {
    height: 100%;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        padding: 0;
        margin: 0;

        &.selected {
          border-left: 4px solid $kite-blue-2;
          background-color: $kite-light-blue-2;
        }

        .folderTab {
          text-decoration: none;
          display: block;
          padding: 12px 16px;

          .text {
            margin-left: 8px;
            font-size: 16px;
            color: $kite-dark-blue-3;
            line-height: 26px;
          }

          .counter {
            float: right;
            color: $kite-gray-3;
          }
        }
      }
    }
  }
}
