@import 'styles/common.scss';

.LabelListContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mobile {
    height: 100%;
  }

  .labelsPlaceholder {
    @include wh(100%);
    @include flexCenter(column);
    @include padLR(30px);
    text-align: center;
    margin-top: 32px;

    .placeholderIcon {
      margin-bottom: 16px;
    }
  }

  .createButton {
    padding: 24px 16px;
  }

  .simpleBar {
    word-wrap: break-word;

    @media only screen and (max-width: 1700px) {
      height: calc(100vh - 339px);
    }

    @media only screen and (max-width: 1795px) {
      min-height: calc(100vh - 324px);
    }

    @include mobile {
      height: 100%;
    }

    ul {
      padding: 0;
      margin: 0;
    }
    .userLabelList {
      list-style: none;
      padding: 0;
      margin: 0;

      .labelItem {
        list-style: none;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        &:hover {
          background-color: $kite-gray-1;
        }

        &.selected {
          border-left: 4px solid $kite-blue-2;
          background-color: $kite-light-blue-2;
        }

        .labelTab {
          text-decoration: none;
          display: flex;
          align-items: center;
          width: 100%;
          padding: 8px 16px;

          &:focus-visible {
            outline-offset: -4px;
          }

          &:hover {
            background-color: $kite-gray-1;
          }

          .labelIcon {
            min-width: 16px;
          }

          .text {
            margin-left: 8px;
            font-size: 16px;
            color: $kite-dark-blue-3;
            line-height: 26px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-decoration: none;
            padding-right: 16px;
          }

          .counter {
            float: right;
            color: $kite-gray-3;
          }
        }
      }

      .selected {
        &:hover {
          .labelTab {
            background-color: $kite-light-blue-2;
          }
        }
      }
    }

    .customLabelList {
      border-top: 1px solid $kite-gray-2;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
          font-size: 20px;
          font-weight: 500;
          line-height: 26px;
          margin: 0;
          padding: 24px 16px;
        }

        .newLabelBtn {
          @include wh(106px, 26px);
          font-size: 16px;
          padding: 0px;
          margin-right: 16px;

          span {
            display: flex;
            span {
              font-size: 16px;
              line-height: 26px;
              display: flex;
              gap: 10px;
            }

            .icon {
              padding: 0;
              margin: 0px;
            }
          }
        }
      }
    }

    .itemSelected {
      border-left: 4px solid $kite-blue-2;
      background-color: $kite-light-blue-2;
      box-shadow: inset 0px -1px 0px #d8dde6;
    }
  }

  .labelMeter {
    padding: 12px;
    width: 100%;
    border-top: 1px solid $kite-gray-2;
  }
}
