@import 'styles/common.scss';

.modal {
  height: 100%;
  display: flex;
  flex-direction: column;

  &.wrappedContent {
    max-width: 30rem;
  }

  h5 {
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 24px;
  }

  .alert {
    margin-bottom: 32px;
  }

  .textInput,
  .selectOption {
    margin-bottom: 32px;

    @include mobile {
      display: flex;
      justify-content: center;
    }
  }

  .redFont {
    color: $kite-red;
  }
}

.wide {
  width: 100%;
  @include mobile {
    width: initial;
  }
}

.addressBook {
  max-width: 861px;
}
