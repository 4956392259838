@import 'styles/common.scss';

.content {
  z-index: 1075;
  position: relative;
  height: calc(100% - 24px);
  overflow: hidden;
  padding: 24px 0;

  @include mobile {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .xIconButton {
    background-color: transparent;
    border: none;

    :hover {
      cursor: pointer;
      color: $kite-blue-3;
    }

    .kiteIconSizeOverride {
      height: 30px !important;
      width: 30px !important;
    }

    .xIcon {
      @extend .kiteIconSizeOverride;
    }

    @include mobile {
      .kiteIconSizeOverride {
        height: 24px !important;
        width: 24px !important;
      }

      color: $kite-blue-1;
    }
  }

  .signOut {
    padding: 12px;

    .signOutButton {
      width: 100%;
      margin-top: 20px;
    }
  }

  .mobileXIconButton {
    height: 16px !important;
    width: 16px !important;
  }
}
