@import '../../../styles/common.scss';

.linkGroup {
  @include flex(column, center);
  text-align: left;
  padding-top: 20px;
  margin-left: calc(100% / 6);
  padding-left: 0;

  .linkTitle {
    font-size: 20px;
    padding-bottom: 3px;

    .linkAnchor {
      text-decoration: none;
      font-weight: 500;

      color: $kite-black;

      &:hover {
        color: $kite-blue-2;
      }
    }
  }

  .mainHamburgerLinkTitle {
    font-size: 16px;
    text-align: left;
    margin-left: -44px;
    padding: 4px;
    font-weight: 500;
  }
}
