@import 'styles/common.scss';

.kiteSwitchOverride {
  --kite-switch-label-margin-right: 10px;
}

.statusCard {
  padding: 16px;
  height: fit-content;
  border-radius: 0px;

  .status {
    @include flex(row, space-between);
    @extend .kiteSwitchOverride;
    margin-bottom: 16px;
    & > * {
      margin: 0;
    }
  }

  .alert {
    margin-top: 24px;
  }
}
