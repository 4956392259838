@import 'styles/common.scss';

.kiteOverride {
  --text-input-max-width: 100%;
  --kite-text-area-max-width: 100%;
}

.contactFormContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  .backButton {
    @include flexCenter(row);
    justify-content: left;
    padding: 18px;
    text-decoration: none;
    color: $kite-blue-2;
    width: fit-content;

    &:hover {
      text-decoration: none;
    }

    @include mobile {
      position: relative;
      padding-left: 5px;

      span {
        margin-right: 6px;
      }
    }
  }

  .contactForm {
    height: 100vh;
    overflow: auto;

    @media only screen and (min-width: 1024px) {
      width: 695px;
    }

    @include mobile {
      width: 100%;
      height: 100%;
    }

    .newContact {
      margin-left: 16px;
      width: 664px;
      border-radius: 0;

      @include mobile {
        width: 100%;
        margin-left: 0;
      }

      .alert {
        margin-bottom: 24px;
      }

      .formFields {
        border-bottom: 1px solid $kite-gray-2;
      }

      .fieldSection,
      .fieldSectionAddress {
        @include padTB(24px);
        width: 100%;
        border-top: 1px solid $kite-gray-2;
        @include flex(row, initial, baseline);

        .fieldContainer {
          @include flex(row, initial, baseline);
          @include mobile {
            @include flex(column, initial, baseline);
          }
        }

        .fieldContainerAddress {
          @include flex(row, initial, baseline);
          @include mobile {
            @include flex(row, initial, baseline);
          }
        }

        .fieldContainerWithRadio {
          @extend .fieldContainer;

          @include mobile {
            @include flex(row, initial, baseline);
          }

          &:not(:first-of-type) {
            margin-top: 16px;
            position: relative;
          }

          .halfField {
            @include mobile {
              width: calc(100vw - 190px);
            }
          }

          .fullField {
            @include mobile {
              width: calc(100vw - 190px);
            }
          }
        }

        .fieldIcon {
          align-self: flex-start;
          margin-top: 42px;
        }

        .fieldRadio {
          align-self: flex-start;
          margin-top: 42px;
          margin-left: 16px;
          text-transform: capitalize;
        }
        .addButton {
          @extend .backButton;
          margin-left: 16px;
        }
        .address {
          &:not(:first-of-type) {
            margin-top: 16px;
          }
        }

        .cityStateCountry {
          @extend .fieldContainer;
          margin-top: 16px;
          margin-left: 32px;
          width: 416px;
          @include mobile {
            margin-top: 8px;
            margin-left: 16px;
            flex-direction: row;
            flex-wrap: wrap;
            width: unset;
          }
          > :not(:first-child) {
            margin-left: 16px;
            @include mobile {
              margin: 8px 16px;
            }
          }

          @include mobile {
            > :first-child {
              margin: 8px 16px;
            }
          }

          .city {
            width: 200px;
            @include mobile {
              width: 150px;
            }
          }
          .state {
            width: 80px;
          }
          .zipCode {
            width: 100px;
          }
          .country {
            width: 164px;
          }
        }
      }

      .fieldSectionAddress {
        flex-direction: column;
        @include mobile {
          flex-direction: column;
        }
      }

      .halfField {
        width: 200px;
        margin-left: 16px;

        @include mobile {
          width: calc(100vw - 104px);
          margin-bottom: 16px;
          --text-input-max-width: 100%;
        }
      }

      .xButton {
        height: fit-content;
        width: fit-content;
        padding: 0px;
        position: absolute;
        left: -20px;
        top: 40px;
        color: $kite-blue-2;
      }

      .fullField {
        @extend .kiteOverride;
        width: 416px;
        margin-left: 16px;
        textarea {
          margin: 0;
        }

        @include mobile {
          width: calc(100vw - 104px);
        }
      }

      .showButton {
        @extend .backButton;
        p {
          margin-right: 16px;
        }
      }

      .buttonBar {
        padding-top: 32px;

        .showMoreButton {
          margin-bottom: 32px;
        }
      }
    }
  }
}
