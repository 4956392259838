@import 'styles/common.scss';

.labelKebabMenuContainer {
  position: relative;
  top: 3px;

  :global(.kite-menu) {
    background-color: transparent;
    &:active {
      background-color: transparent;
    }
    :global(.kite-menu__trigger) {
      width: 20px;
      padding: 0;
      &:hover {
        background-color: transparent;
      }
      &:active {
        background-color: transparent;
      }
    }
    :global(.kite-menu__list) {
      width: 188px;

      .hideOnDesktop {
        display: none;

        @include mobile {
          display: initial;
        }
      }

      .redIcon {
        svg {
          fill: $kite-dark-red;
        }
      }
    }

    :global(.kite-icon svg) {
      fill: $kite-blue-2;
    }
  }

  :global(.kite-menu.kite-menu--top) {
    :global(.kite-menu__list) {
      top: 80px;
    }
  }

  :global(.kite-menu.kite-menu--bottom) {
    bottom: unset;
    :global(.kite-menu__list) {
      top: 0px;
    }
  }

  :global(.kite-menu.kite-menu--right) {
    :global(.kite-menu__list) {
      left: 20px;
    }
  }

  :global(.kite-menu--open .kite-menu__trigger) {
    &:hover {
      background-color: transparent;
    }
    background-color: transparent;

    :global(.kite-icon svg) {
      fill: $kite-blue-2;
    }
  }
}

:global(.fixedPosition) {
  position: absolute;
  right: 16px;
}

:global(.deleteMenuItem) {
  color: $kite-red !important;
  .icon {
    color: $kite-red !important;
  }
}
