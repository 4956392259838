@import 'styles/common.scss';

.section {
  @include flex(row, flex-start, center);
}

.mobileSection {
  @extend .section;
  display: none;

  @include mobile {
    display: flex;

    .folderListButton {
      margin-left: 10px;
      min-width: unset !important;
    }
  }
}

.messageOptions {
  :global(.kite-button.focus-visible) {
    height: 24px;
  }

  @extend .section;
  width: 100%;

  @include mobile {
    display: flex;
  }

  &.messageOptionsDisable {
    opacity: 50%;
    pointer-events: none;
  }

  .messageOptionsSelected {
    @extend .section;
    width: 100%;
    @include mobile {
      > .menu-list > :first-child {
        display: none;
      }
    }
  }

  .sortMessagesButton {
    :global(.kite-menu__trigger.focus-visible) {
      height: 24px;
    }

    position: relative;
    @extend .section;

    @include mobile {
      display: flex;
    }

    .sortDrowpdownContainer {
      display: flex;
      position: absolute;
      top: 50px;
      left: 0px;
      background-color: $kite-white;
      z-index: 100;
    }
  }

  .menuList {
    display: flex;
    align-items: center;
    width: 100%;
  }

  label.messageListOptionsButton {
    color: $kite-blue-2;
    display: flex;
    gap: 4px;
    align-items: baseline;
    white-space: nowrap;
    margin-left: 10px;
    cursor: pointer;

    @include mobile {
      margin-right: 10px;
    }

    @media (max-width: 474px) {
      .label {
        display: none;
      }
    }
  }
}

.fixedWidth {
  width: 336px;
  margin-right: 12px;

  @include mobile {
    justify-content: flex-start;
  }
}

.sectionRight {
  @extend .section;
  justify-content: flex-end;
}

.selectButtonBorder {
  content: ' ';
  height: 26px;
  width: 1px;
  background-color: $kite-gray-2;
}

.refreshButton :global(.kite-icon) {
  @include mobile {
    margin-right: 0 !important;
  }
}

.messageOptionsButton {
  @include flexCenter();
  --icon-margin: 9px;
  font-weight: 400;
  * {
    margin: 0;
  }

  .messageSortText {
    @include mobile {
      display: none;
      margin: 0;
      padding: 0;
      & + :global(.kite-icon) {
        margin-right: 0 !important;
        background-color: red;
      }
    }

    & + :global(.kite-icon) {
      margin-right: 0 !important;
      background-color: red;
    }
  }

  @media (max-width: 350px) {
    .label {
      display: none;
    }
  }
}

.deleteButton {
  color: $kite-red-20;
}

.otherActions {
  color: black;
  margin-right: 6px;
}
