@import 'styles/common.scss';

.listViewContainer {
  height: 100%;
  @include flex(column, initial, flex-start);
  min-width: 336px;
  background-color: transparent;
  text-decoration: none;
  color: $kite-black;
  overflow: hidden;
  padding: 0px 8px;

  @include mobile {
    height: 100%;
    width: 100%;
    padding: 0;
    min-width: initial;
  }

  .listOptionsContainer {
    @include wh(100%, 42px);
    @include flex(row, flex-start, center);
    min-height: 42px;
    color: $kite-black;
  }

  .headerContainer {
    width: 100%;

    @include mobile {
      padding: 0px;
    }

    .inboxHeaderLabel {
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      padding: 10px 24px;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 0px;
      background-color: $white;
      border: 1px solid $kite-gray-2;
    }

    .columnLabels {
      display: flex;
      padding: 4px;
      border-width: 1px 1px 0px 1px;
      border-color: $kite-gray-2;
      border-style: solid;
      font-size: 14px;
      width: 100%;
      justify-content: space-between;
      background-color: $kite-white;

      @include mobile {
        display: none;
      }

      .fromToColumn {
        width: 30%;
        padding-left: 76px;
      }

      .label {
        border-left: 1px solid $kite-gray-2;
        padding-left: 8px;
        text-transform: capitalize;
      }

      .subjectAndDateColumn {
        width: 70%;
        display: flex;
        justify-content: space-between;

        .subject {
          margin-left: 4px;
        }

        .date {
          padding-right: 40px;
        }
      }
    }
  }

  .listViewCardContainer {
    width: 100%;
    height: 100%;
    border-top: 1px solid $kite-gray-2;
    @include flex(column, initial, center);
  }
}
