@import 'styles/common.scss';

.subheader {
  padding: 12px 24px;
  background-color: $kite-white;
  border: 1px solid $kite-gray-2;
  border-top: none;
  border-left: none;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  border-right: 0px;

  @include mobile {
    border-top: 1px solid $kite-gray-2;
    border-left: 1px solid $kite-gray-2;
  }
}
