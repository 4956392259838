@import 'styles/common.scss';

.composeContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  right: 0;
  width: 616px;
  height: 650px;
  align-self: flex-end;
  margin-left: 8px;
  border: 1px solid $kite-gray-2;
  background-color: $kite-white;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);

  @media (max-height: 725px) {
    height: calc(100vh - 77px);
  }

  @include mobile {
    height: 100%;
    width: 100%;
    margin-left: 0px;
    pointer-events: all;
  }

  .composeFormScrollable {
    min-height: calc(100% - 161px);
    margin-bottom: 0;
    overflow-x: hidden;

    @include mobile {
      height: 100vh;
    }

    .attachmentsContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 8px 8px;

      .attachmentTag {
        width: fit-content;
        border: 1px solid #d8dde6;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 4px 10px 4px 20px;
        display: flex;
        align-items: center;
        min-width: 100px;
        max-height: 36px;

        .name {
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
          margin-right: 8px;
          color: $kite-blue-2;
          text-decoration: none;
          display: flex;
          align-items: center;
          min-width: 0;

          .span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .attachmentIcon {
            margin-right: 12px;
            min-width: 15px;
          }
        }

        .size {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          margin-right: 8px;
          flex-shrink: 0;
        }

        .deleteAttachment {
          color: $kite-blue-2;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .compact {
    width: 100%;
    height: 200px;
    position: absolute;
    right: 0;
    bottom: 0;
    background: $kite-white;
    z-index: 2000;
    padding: 0px !important;
  }

  .cardMinimized {
    display: none;
    height: 0;
  }

  .minimized {
    height: 0px;
    bottom: 55px;
    right: 0;
    position: absolute;
  }

  .headerMobile {
    width: 616px;
    position: relative;
  }

  .headerMinimized {
    width: 304px;
    background-color: $kite-dark-blue-1;
    z-index: 1600;
    right: 0;
    padding: 0 8px;
    border-radius: 4px;

    @include mobile {
      width: 100%;
    }

    h4 {
      max-width: 46%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .headerExpanded {
    z-index: 1000;

    @include mobile {
      width: 100%;
      position: relative;
    }
  }

  .expanded {
    width: 616px;
    height: 650px;
    position: absolute;
    bottom: 5px;
    right: 0;
    border: 1px solid blue;
    margin: auto;
    border: 1px solid $kite-gray-2;
    background: $kite-white;
    z-index: 200;

    @media only screen and (max-width: 1200px) {
      margin-bottom: 26px;
    }

    @media only screen and (max-width: 1187px) {
      margin-bottom: 6px;
    }

    @media only screen and (max-width: 1108px) {
      margin-bottom: 8px;
    }
  }

  .mobile {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 30px;
    right: 0;
    top: 0;
    margin: auto;
    border: 1px solid $kite-gray-2;
    background: $kite-white;
    // z-index: 200;
  }

  .composeFormContainer {
    padding: 0 16px;
    border: none;
    border-radius: 0px;
    position: relative;
    display: flex;
    width: 614px;
    flex-direction: column;
    height: 592px;

    @media only screen and (max-width: 1024px) {
      position: relative;
      width: 100%;
      height: calc(100dvh - 64px);
    }

    .draftNotification {
      padding-left: 8px;
      font-size: 14px;
      height: 26px;
      line-height: 20px;
      color: $kite-disabled-gray-dark;
      width: 94%;
      margin: 0;
      margin-bottom: 10px;

      &.draftNotificationHide {
        visibility: hidden;
      }
    }

    .draftNotificationCard {
      padding-left: 8px;
      position: absolute;
      font-size: 14px;
      line-height: 20px;
      bottom: 130px;
      color: $kite-disabled-gray-dark;
      width: 94%;

      p {
        margin-bottom: 0px;
      }

      .alertContainer {
        position: relative;
        display: flex;

        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        margin-top: 15px;

        .error {
          width: 100%;
        }
      }
    }
  }
}

.iconGroup {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 10px;
  gap: 2px;

  .composeButtons {
    background-color: transparent;
    color: $kite-white;
    border: none;
    cursor: pointer;
  }

  .closeButton {
    color: $kite-white;
  }

  .rk-button {
    padding: 0px;
  }
}

.composeContainerMinimized {
  border: 0;
  justify-content: flex-end;
  box-shadow: none;
  background: none;
  width: fit-content;
  display: flex;
  align-items: flex-end;

  @include mobile {
    width: 100%;
  }
}

.maximized {
  height: 100%;
  width: 100%;
  margin-left: 0px;
  pointer-events: all;

  .composeFormScrollable {
    height: 100%;
  }

  .composeFormContainer {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
