@import 'styles/common.scss';

.title {
  font-size: 24px;

  @include mobile {
    padding-right: 100px;
  }
}

.okButton {
  width: 206px;

  @include mobile {
    width: 100%;
  }
}
