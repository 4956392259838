@import 'styles/common.scss';

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;

  p {
    margin: 0;
  }
  .tryAgain {
    background: none;
    border: none;
    text-decoration: underline;
    padding: 0;
    margin: 0;
    bottom: 1px;
    color: $kite-blue-2;
    height: fit-content;
    width: fit-content;
    bottom: 1px;
  }
}
