@import '../../../styles/common.scss';

.navTabs {
  flex: 0;

  .navTab {
    display: flex;
    align-self: center;
    text-align: center;
    --kite-link-text-color: $kite-black;
    --kite-link-text-decoration: none;

    &:focus-visible {
      outline: none;
    }

    &:focus-visible .navIconContainer {
      outline: auto;
      outline-color: var(--focus-ring-color);
      outline-offset: 0.1em;
    }

    &:hover {
      cursor: pointer;
      background: $kite-gray-1;
      --kite-link-text-decoration: underline;
      --kite-link-hover-text-color: $kite-black;

      .selectedTabIndicator {
        background: $kite-gray-2;
      }
    }

    .selectedTabIndicator {
      @include wh(4px, 76px);
      background: $kite-white;
    }

    .indicatorSelectedState {
      background: $kite-blue-2;

      &:hover {
        background: $kite-blue-2;
      }
    }

    .navIconContainer {
      @include flexCenter(column);
      margin: auto;
      position: relative;

      .navSettingIcon {
        position: absolute;
        right: 0px;
        top: 0px;
      }

      .navTabName {
        font-size: 12px;
        margin-bottom: 0px;
        font-weight: 400;
      }
    }
  }

  .navTabSelectedState {
    background: $kite-light-blue-2 !important;

    &:hover {
      background-color: $kite-light-blue-2 !important;
      .selectedTabIndicator {
        background: $kite-blue-2 !important;
      }

      .navIconContainer {
        --kite-link-text-decoration: none;
      }
    }
  }
}
