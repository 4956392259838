@import 'styles/common.scss';

.kiteSwitchOverride {
  --kite-switch-label-margin-right: 10px;
}

.autoReplyStatusTray {
  @include wh(100%);
  max-width: 664px;

  @include mobile {
    max-width: none;
  }

  .autoReplyStatusCard {
    padding: 16px;
    height: fit-content;

    .autoReplyStatus {
      @include flex(row, space-between);
      @extend .kiteSwitchOverride;
      margin-bottom: 16px;
      & > * {
        margin: 0;
      }
    }

    .alert {
      margin-top: 24px;
    }
  }

  .textAreaCard {
    min-height: 396px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 24px 16px 16px 16px;
    margin-top: 16px;
    border-radius: 0;

    p {
      margin-bottom: 4px;
    }

    textarea {
      resize: none;
      height: 194px;
      margin-bottom: 32px;
    }

    .buttonGroup {
      display: flex;

      .saveButton {
        width: 200px;
        min-height: 44px;

        .group {
          .icon {
            height: 19px;
            width: 19px;
          }

          .text {
            line-height: 14px;
            vertical-align: text-top;
          }
        }
      }

      .cancelButton {
        display: inline-block;
        width: 200px;
        min-height: 44px;
        margin-right: 16px;
      }

      .success {
        color: $kite-white;
        width: 200px;
        background-color: $kite-green-20;
      }
    }
  }
}
