@import '../../../styles/common.scss';

.modalContainer {
  height: 100%;
  width: 100%;

  .header {
    font-size: 24px;
    margin-top: -7px;
  }

  .bodyText {
    font-size: 16px;
    margin-top: 13px;
    margin-bottom: 24px;
  }

  .actionButtons {
    display: flex;
    width: 100%;
    gap: 25px;
    justify-content: center;
    align-items: center;

    .getItLater {
      width: 244px;
    }

    .learnMore {
      width: 244px;
    }
  }
}

.canarySpinner {
  width: '100vw';
  height: '100vh';
  background: 'white';
  z-index: 2000;
}
