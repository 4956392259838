$small: 360px;
$med: 1024px;
$large: 1200px;

$white: white;

$canaryBlue: #0073d1;
$canaryDarkBlue: #002133;
$canaryBoxShadow: rgba(0, 0, 0, 0.5);
$canaryBlueGrey: #63738a;
$kite-green-20: #008516;
$kite-gray-30: #63738a;
$kite-red-20: #d6312b;
$kite-disabled-gray-dark: #bdbdbd;
$headerHeight: 72px;
