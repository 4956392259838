@import 'styles/common.scss';

.SkipLinks {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: $white;
  z-index: 1;
  top: 0;
  padding: 0.25rem 0.5rem;
  border-top: none;
  border-radius: 0 0 var(--border-radius) var(--border-radius);

  &:focus-visible,
  &:focus-within {
    left: 12px;
    width: auto;
    height: auto;
  }

  .SkipLink {
    text-decoration: none;
    color: $canaryBlue;
  }
}
