@import 'styles/common.scss';

.section {
  :global(.kite-button.focus-visible) {
    height: 24px;
  }

  @include flex(row, flex-start, center);
  @include mobile {
    @include flex(row, space-between, center);
  }
  width: 100%;

  .sectionLeft {
    @include flex(row, flex-start, center);

    @include mobile {
      position: relative;
      left: -12px;
    }

    .mobileSection {
      display: none;

      @include mobile {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .sectionRight {
    padding-right: 8px;
    @include mobile {
      padding-right: 0;
    }
    @include flex(row, flex-end, center);

    .messageOptionsButton {
      @include flexCenter();
      --icon-margin: 9px;
      font-weight: 400;

      @media only screen and (max-width: 320px) {
        --icon-margin: 4px;
      }
    }
  }

  label.messageOptionsButton {
    color: $kite-blue-2;
    display: flex;
    gap: 4px;
    align-items: baseline;
    white-space: nowrap;
    margin-left: 10px;
    cursor: pointer;

    @include mobile {
      margin-right: 10px;
    }

    @media (max-width: 430px) {
      .label {
        display: none;
      }
    }
  }

  .selectButtonBorder {
    content: ' ';
    height: 26px;
    width: 1px;
    background-color: $kite-gray-2;
  }

  .contactOptionsButton {
    @include flexCenter();
    --icon-margin: 9px;
    font-weight: 400;
    @media only screen and (max-width: 320px) {
      --icon-margin: 4px;
    }

    * {
      margin: 0;
    }
  }

  .deleteButton {
    color: $kite-red-20;
  }

  .otherActions {
    color: black;
    margin-right: 6px;
    @include mobile {
      display: none;
    }
  }
}
