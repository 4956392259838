@import 'styles/common.scss';

.mainPage {
  background-color: $kite-gray-1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;

  @media (max-width: 1280px) {
    justify-content: flex-start;
    align-items: flex-start;
  }

  @media (max-height: 880px) and (min-width: 1280px) {
    justify-content: center;
    align-items: flex-start;
  }

  .contentWrapper {
    position: relative;
    width: fit-content;
    height: fit-content;

    .dialogContainer {
      height: fit-content;
      width: 300px;
      position: relative;
      border-radius: 16px;
      background-color: $kite-white;
      padding: 16px;
      border: none;
      z-index: 2;

      .hidden {
        display: none;
      }

      .header {
        font-size: 24px;
        width: 260px;
        line-height: 28px;
      }

      .secondary {
        font-size: 18px;
        width: 260px;
        margin-top: 2px;
        margin-bottom: 18px;
      }

      .pageCount {
        font-size: 14px;
        margin-top: -6px;
        text-align: center;
      }

      .exitButton {
        background-color: transparent;
        border: none;
        height: 10px;
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;

        .exitIcon {
          margin-top: 5px;
          margin-right: -2px;
        }
      }

      .arrowLeft {
        right: 300px;
        top: 43px;
        border-top: 18px solid transparent;
        border-bottom: 18px solid transparent;
        position: absolute;
        border-right: 27.71px solid white;
      }

      .actionButtons {
        display: flex;
        position: absolute;
        margin-left: -16px;
        margin-top: -15px;
        width: 300px;
        font-size: 16px;

        .prevButton {
          color: $kite-black;
          border-radius: 0% 0% 0% 16px;
          background-color: $kite-white;
          border: 1px solid $kite-gray-2;
          border-right: 0px;
        }
        .nextButton {
          color: $kite-black;
          border-radius: 0% 0% 16px 0%;
          background-color: $kite-white;
          border: 1px solid $kite-gray-2;
        }
      }
    }

    .sideNavPos {
      position: absolute;
      top: 48px;
      left: 110px;
    }

    .settingsPos {
      position: absolute;
      top: 152px;
      left: 364px;
    }

    .spamPos {
      position: absolute;
      top: 434px;
      left: 364px;
    }

    .filterRulesPos {
      position: absolute;
      top: 400px;
      left: 400px;
    }

    .signaturePos {
      position: absolute;
      top: 186px;
      left: 364px;
    }

    .bulkActionsPos {
      position: absolute;
      top: 42px;
      left: 6px;
    }

    .screenshot {
      filter: drop-shadow(0px 0px 9px rgba(0, 0, 0, 0.25));
    }

    .canarySpinner {
      width: '100vw';
      height: '100vh';
      background: 'white';
      z-index: 2000;
    }
  }

  .contentWrapperSmall {
    height: 818px;
  }

  .arrowRight {
    left: 297px;
    top: 40px;
    transform: rotate(180deg);
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;
    position: absolute;
    border-right: 27.71px solid white;
  }
}
