@import 'styles/common.scss';

.assignLabelToContact {
  .simpleBar {
    min-height: 32vh;
    margin-bottom: 20px;
  }

  .labelsList {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      list-style: none;
    }
  }
}
