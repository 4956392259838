@import 'styles/common.scss';

.emailViewContainer {
  position: relative;
  height: 100%;
  padding: 0px 8px 0px 0px;

  &.noneSelected {
    padding-top: 58px;
  }

  @include mobile {
    width: 100%;
    padding: 0;
    overflow-x: hidden;
  }

  .selectAnEmailPlaceholder {
    @include flex(column, center, center);
    @include marLR(auto);
    max-width: 260px;
    text-align: center;
    height: 100%;

    .placeholderIcon {
      margin-bottom: 16px;
    }

    @include mobile {
      display: none;
    }
  }

  .emailOptionsContainer {
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .emailDetailsCard {
    padding: 0px;
    border-radius: 0px;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .errorContainer {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .notFoundContainer {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      @include mobile {
        min-height: inherit;
      }

      .card {
        max-width: 482px;

        .icon {
          width: 90px;
          margin-bottom: 40px;
        }
      }
    }

    &.noneSelected {
      height: 100%;
    }

    @include mobile {
      height: 100%;
      min-height: 75dvh;
    }

    .simpleBar {
      overflow-x: hidden;

      @include mobile {
        min-height: fit-content;
        max-height: fit-content;
      }
    }

    .row {
      display: flex;
      width: 100%;

      .columnTwo {
        flex-grow: 1;
      }

      .columnThree {
        display: flex;
        justify-content: flex-end;
        @include mobile {
          justify-content: center;
        }
      }
    }

    .emailContent {
      padding: 16px;
      & > div > .row {
        flex-wrap: wrap;
      }
    }

    .paddingNameImage {
      margin-left: 16px;
    }

    .priority {
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 16px;
      background-color: $kite-gray-1;
      border-bottom: 1px solid $kite-gray-2;

      .highPriorityIcon {
        color: $kite-red-20;
      }
    }

    .profileIcon {
      @include wh(48px);
      background-color: $kite-gray-2;
      border-radius: 48px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .subject {
      padding: 16px;
      border-bottom: 1px solid $kite-gray-2;
      font-weight: 500;
      display: flex;
      justify-content: space-between;

      .emailTitle {
        font-size: 16px;
        margin-bottom: 0px;
      }

      .actionButtons {
        position: relative;
        display: flex;
        gap: 18px;
        padding-left: 10px;

        .expandToggle {
          position: relative;
          width: 18px;
          height: 18px;
          padding: 0;

          @include mobile {
            display: none;
          }
        }

        .closeContactView {
          .close {
            color: $kite-blue-2;
          }
        }
      }
    }

    .address {
      margin-right: 8px;
      word-wrap: break-word;
      white-space: pre;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;

      .itemSecondary {
        color: $kite-gray-3;
        font-size: 14px;
        margin-left: 8px;
      }
    }

    .from {
      @extend .address;
      display: flex;
      align-items: baseline;
      flex-direction: row;
      margin-bottom: 4px;

      @include mobile {
        flex-direction: column;
      }
    }

    .recipient {
      display: flex;
      flex-direction: column;
      .row {
        margin-bottom: 4px;
      }

      .recipientListBox {
        padding-left: 8px;
        width: 100%;
        display: flex;
        align-items: center;
      }

      .recipientListBoxShow {
        @extend .recipientListBox;
        display: block;
        padding-top: 2px;
        overflow: hidden;
      }

      .recipientLabel {
        background: $kite-gray-2;
        font-size: 14px;
        padding: 4px;
      }

      .recipientList {
        font-size: 14px;
        max-height: 22px;
        max-width: 20ch;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        margin-right: 8px;

        .address:not(:last-child)::after {
          content: ';';
        }
      }

      .recipientListShow {
        @extend .recipientList;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        max-height: none;
        max-width: none;
        flex-direction: column;
      }

      .kiteBtnOverride {
        font-size: 14px;
        width: fit-content;
        height: fit-content;
        padding: 0;
      }

      .inlineToggle {
        @extend .kiteBtnOverride;
        display: inline;
      }
    }

    .timeWithReplyOptions {
      display: flex;
      flex-flow: column;
      flex-direction: column;

      .time {
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
      }

      .replyOptions {
        display: flex;
        flex-flow: column;

        .replyOptionButton {
          font-weight: 400;
          padding: 0;
          margin-right: 24px;
        }

        ul,
        li {
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;

          @include mobile {
            flex-wrap: wrap;
          }
        }

        li {
          &:last-child {
            .replyOptionButton {
              margin-right: 0;
            }
          }
        }
      }
    }

    .attachments {
      padding-bottom: 16px;

      .attachmentButton {
        color: $kite-black;
        font-weight: 400;
      }

      .attachmentCountName {
        padding-right: 12px;
      }

      .attachmentCountChevron {
        color: $kite-blue-2;
      }

      .attachmentCount {
        margin-bottom: 8px;
      }

      .attachmentList {
        display: flex;
        gap: 4px;
        flex-wrap: wrap;
      }
    }

    .whiteSpaceBreak {
      white-space: pre-line;
      margin-bottom: 100px;
    }

    .emailIframe {
      position: relative;
      margin-top: 16px;
      padding-top: 16px;
      border-top: 1px solid $kite-gray-2;

      & > iframe {
        height: 0;
        width: 100%;
        border: none;
        overflow: hidden;
      }
    }
  }

  .disablePointerEvents {
    pointer-events: none;
  }

  .selectedEmail {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    @include mobile {
      display: none;
    }

    .selectedEmailsDescription {
      position: absolute;
      top: 52%;
    }
  }

  .flagIcon {
    display: flex;
    justify-content: flex-end;
  }

  .progressSpinnerContainer {
    @include mobile {
      height: 100vh;
    }
  }
}

.expandedPadding {
  padding-left: 8px;

  @include mobile {
    padding-left: 0px;
  }
}
