@import 'styles/common.scss';

.section {
  @include flex(row, flex-start, center);
}

.mobileSection {
  @extend .section;
  display: none;

  @include mobile {
    display: flex;

    .labelListButton {
      margin-left: 10px;
      min-width: unset !important;
    }
  }
}

.contactOptions {
  @extend .section;
  width: 100%;

  :global(.kite-button.focus-visible) {
    height: 24px;
  }

  .contactOptionsSelected {
    @extend .section;
    width: 100%;
  }

  .sortMessagesButton {
    @extend .section;
    @include mobile {
      display: none;
    }
  }

  .menuList {
    display: flex;
    align-items: center;
    width: 100%;

    @include mobile {
      justify-content: flex-start;
    }

    .moreOptions {
      align-self: center;
      justify-self: center;
    }
  }

  .noSpacing {
    justify-content: unset;

    @include mobile {
      justify-content: flex-start;
    }
  }
}

label.contactListOptionsButton {
  color: $kite-blue-2;
  display: flex;
  gap: 4px;
  align-items: baseline;
  white-space: nowrap;
  margin-left: 10px;
  cursor: pointer;

  @include mobile {
    margin-right: 10px;
  }

  @media (max-width: 474px) {
    .label {
      display: none;
    }
  }
}

.sectionRight {
  @extend .section;
  justify-content: flex-end;
}

.selectButtonBorder {
  content: ' ';
  height: 26px;
  width: 1px;
  background-color: $kite-gray-2;
  // border-right: solid 1px $kite-gray-2;
}

.contactOptionsButton {
  @include flexCenter();
  --icon-margin: 9px;
  font-weight: 400;
  * {
    margin: 0;
  }
}

.syncButton {
  padding: 0;

  .syncIcon {
    margin-right: 10px;
    height: 18px;
  }

  .syncLabel {
    @media (max-width: 410px) {
      display: none;
    }
  }
}

.importLabel {
  @media (max-width: 360px) {
    display: none;
  }
}
.deleteButton {
  color: $kite-red-20;

  @media (max-width: 360px) {
    padding-right: 8px;
    padding-left: 12px;

    .label {
      display: none;
    }
  }
}

.otherActions {
  color: black;
  margin-right: 6px;
}

.fixedWidth {
  width: 336px;
  min-width: 336px;

  @include mobile {
    justify-content: flex-start;
  }
}
